import React, { useState, useEffect, useRef } from 'react';

const CustomerPricing = ({}) => {
  const [iframeHeight, setIframeHeight] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const containerElement = containerRef.current;
      if (containerElement) {
        const containerWidth = containerElement.offsetWidth;
        const aspectRatio = 9 / 16; // Assuming 16:9 aspect ratio for iframe content
        setIframeHeight(containerWidth * aspectRatio);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize(); // Call initially to set height

    return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
  }, []);

  return (
    <div ref={containerRef} style={{ position: 'relative', width: '100%', height: 0, paddingTop: '56.2225%', paddingBottom: 0, boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)', marginTop: '1.6em', marginBottom: '0.9em', overflow: 'hidden', borderRadius: '8px', willChange: 'transform' }}>
      <iframe
        style={{ position: 'absolute', width: '100%', height: iframeHeight, top: 0, left: 0, border: 'none', padding: 0, margin: 0 }}
        loading="lazy"
        src="https://www.canva.com/design/DAGQY9EtWKo/1LfFiEO5sOCSDjRJv4-6Wg/view?embed"
        allowfullscreen="allowfullscreen"
        allow="fullscreen"
      />
      <a
        href="https://www.canva.com/design/DAGQY9EtWKo/1LfFiEO5sOCSDjRJv4-6Wg/view?utm_content=DAGQY9EtWKo&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Beige Black White Photo and Videocentric Coming Soon Website Coming Soon Page
      </a>
      
    </div>
  );
};

export default CustomerPricing;