import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container, Typography, Box, Button, Grid, TextField, Paper, Card, CardContent, CardActionArea, CardActions, IconButton
} from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { createEvent, listUpcomingEvents } from './GoogleCalendarService';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import backgroundImg from '../assets/pexels-vlada-karpovich-9969234.jpg'; // Example background image

const Root = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  minHeight: '100vh',
  backgroundImage: `url(${backgroundImg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: theme.palette.text.primary,
}));

const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: '#fff',
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}));

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  textTransform: 'none',
  padding: theme.spacing(1.5),
  margin: theme.spacing(0.5),
  boxShadow: theme.shadows[1],
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const DatePickerWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
  '& .MuiTextField-root': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
  },
}));

const SlotCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    background: {
      default: '#f4f6f8',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 500,
    },
  },
});

const ScheduleDemo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { formValues } = location.state || {};
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment().startOf('day'));

  useEffect(() => {
    const fetchAvailableSlots = async () => {
      try {
        const timeMin = momentTimezone().toISOString();
        const timeMax = momentTimezone().add(7, 'days').toISOString();
        const events = await listUpcomingEvents(timeMin, timeMax);

        const busySlots = events.map(event => ({
          start: momentTimezone(event.start.dateTime).toISOString(),
          end: momentTimezone(event.end.dateTime).toISOString(),
        }));

        const generatedSlots = generateAvailableSlots(busySlots);
        setAvailableSlots(generatedSlots.filter(slot => moment(slot.start).isAfter(moment())));
      } catch (error) {
        console.error('Error fetching available slots:', error);
        setError('Error fetching available slots. Please try again.');
      }
    };

    fetchAvailableSlots();
  }, []);

  const generateAvailableSlots = (busySlots) => {
    const startHour = 9;
    const endHour = 17;
    const slots = [];
    for (let day = 0; day < 7; day++) {
      for (let hour = startHour; hour < endHour; hour++) {
        const start = momentTimezone().add(day, 'days').hour(hour).minute(0).second(0);
        const end = moment(start).add(1, 'hour');

        if (!busySlots.some(slot =>
          momentTimezone(slot.start).isBetween(start, end) ||
          momentTimezone(slot.end).isBetween(start, end))) {
          slots.push({ start: start.toISOString(), end: end.toISOString() });
        }
      }
    }
    return slots;
  };

  const handleSelectSlot = (slot) => {
    setSelectedSlot(slot);
  };

  const handleSchedule = async () => {
    if (selectedSlot) {
      const event = {
        summary: `Demo with ${formValues.name}`,
        start: { dateTime: selectedSlot.start },
        end: { dateTime: selectedSlot.end },
        customerEmail: formValues.email,
      };

      try {
        const eventDetails = await createEvent(event);
        navigate('/demo-confirmed', { state: { eventDetails } });
      } catch (error) {
        console.error('Error scheduling demo:', error.message || error);
        setError('Error scheduling demo. Please try again.');
      }
    }
  };

  const filterSlotsByDate = (date) => {
    const startOfDay = moment(date).startOf('day');
    const endOfDay = moment(date).endOf('day');
    return availableSlots.filter(slot => moment(slot.start).isBetween(startOfDay, endOfDay));
  };

  return (
    <ThemeProvider theme={theme}>
      <Root>
        <Header>
          <Typography variant="h4">Schedule a Demo</Typography>
          {error && <Typography color="error">{error}</Typography>}
        
        <Container maxWidth="md">
          <Box mb={4}>
            <Typography variant="h6" gutterBottom align="center">Select a date for your demo:</Typography>
            <DatePickerWrapper>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                  minDate={moment()}
                  maxDate={moment().add(6, 'days')}
                />
              </LocalizationProvider>
            </DatePickerWrapper>
          </Box>
          <Box mb={4}>
            <Typography variant="h6" gutterBottom align="center">Select a time slot for your demo:</Typography>
          </Box>
          <Grid container spacing={3} justifyContent="center">
            {filterSlotsByDate(selectedDate).map((slot, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <SlotCard>
                  <CardActionArea onClick={() => handleSelectSlot(slot)}>
                    <CardContent>
                      <Typography variant="h6">
                        {moment(slot.start).format('LLL')}
                      </Typography>
                      <Box display="flex" alignItems="center">
                        <AccessTimeIcon />
                        <Typography variant="body2" ml={1}>
                          {moment(slot.start).format('LT')} - {moment(slot.end).format('LT')}
                        </Typography>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </SlotCard>
              </Grid>
            ))}
          </Grid>
          {selectedSlot && (
            <Box mt={4} textAlign="center">
              <Typography variant="h6">
                Selected Time: {moment(selectedSlot.start).format('LLL')}
              </Typography>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={handleSchedule}
                sx={{ mt: 2 }}
              >
                Confirm and Schedule Demo
              </CustomButton>
            </Box>
          )}
          
        </Container>
        </Header>
      </Root>
    </ThemeProvider>
  );
};

export default ScheduleDemo;
