import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  IconButton,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Sidebar from './Sidebar';
import { database, fetchCompanies } from './Firebase';
import { ref, push, set, update } from 'firebase/database';

const industryOptions = ['Solar', 'Roofing', 'Alarms', 'Smart Systems', 'HVAC', 'Windows', 'Pest', 'Insurance'];
const billingTierOptions = ['M2M', '50+', '100+', '150+'];

const CompanyListing = () => {
  const theme = useTheme();
  const [companies, setCompanies] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [newCompanyName, setNewCompanyName] = useState('');
  const [newCompanyIndustry, setNewCompanyIndustry] = useState('');
  const [newCompanyBillingTier, setNewCompanyBillingTier] = useState('');
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadCompanies = async () => {
      try {
        const companiesData = await fetchCompanies();
        const updatedCompaniesData = companiesData.map(company => {
          if (!company.uniqueUrl) {
            const uniqueUrl = `${Math.random().toString(36).substring(2, 15)}_${Math.random().toString(36).substring(2, 15)}`;
            company.uniqueUrl = uniqueUrl;
            const companyRef = ref(database, `companies/${company.id}`);
            update(companyRef, { uniqueUrl });
          }
          return company;
        });
        setCompanies(updatedCompaniesData);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    loadCompanies();
  }, []);

  const handleSearch = () => {
    const filtered = companies.filter(company =>
      company.name && company.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCompanies(filtered);
    setSearchPerformed(true);
  };

  const handleCreateCompany = async () => {
    if (newCompanyName.trim() === '' || newCompanyIndustry.trim() === '' || newCompanyBillingTier.trim() === '') return;

    try {
      const existingIds = companies.map(company => company.companyId).filter(Boolean);
      const nextId = existingIds.length > 0 ? Math.max(...existingIds) + 1 : 1;
      const uniqueUrl = `${Math.random().toString(36).substring(2, 15)}_${Math.random().toString(36).substring(2, 15)}`;
      const newCompanyRef = push(ref(database, 'companies'));
      const newCompany = {
        companyId: nextId,
        name: newCompanyName,
        industry: newCompanyIndustry,
        uniqueUrl
      };
      await set(newCompanyRef, newCompany);

      setCompanies([...companies, { ...newCompany, id: newCompanyRef.key }]);
      setFilteredCompanies([...filteredCompanies, { ...newCompany, id: newCompanyRef.key }]);
      setNewCompanyName('');
      setNewCompanyIndustry('');
      setNewCompanyBillingTier('');
      setIsCreateDialogOpen(false);
    } catch (error) {
      console.error('Error creating company:', error);
    }
  };

  const openCreateDialog = () => {
    setIsCreateDialogOpen(true);
  };

  const closeCreateDialog = () => {
    setIsCreateDialogOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', backgroundColor: theme.palette.background.default }}>
      <Sidebar />
      <Box sx={{ flex: 1, padding: '20px', backgroundColor: theme.palette.background.default }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            marginTop: '20px',
          }}
        >
          Company Listing
        </Typography>
        <Divider />
        <Box mt={2} display="flex" alignItems="center">
          <TextField
            label="Search Company"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            sx={{ marginRight: '10px' }}
          />
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
          <Button variant="contained" color="secondary" onClick={openCreateDialog} sx={{ marginLeft: '10px' }}>
            Create New Company
          </Button>
        </Box>
        <List>
          {(searchPerformed ? filteredCompanies : companies).map((company) => (
            <ListItem key={company.id}>
              {company.name}
              <IconButton edge="end" onClick={() => navigate(`/companies/${company.uniqueUrl}`)}>
                <EditIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Dialog open={isCreateDialogOpen} onClose={() => setIsCreateDialogOpen(false)}>
          <DialogTitle>Create New Company</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Company Name"
              type="text"
              fullWidth
              value={newCompanyName}
              onChange={(e) => setNewCompanyName(e.target.value)}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="industry-label">Industry</InputLabel>
              <Select
                labelId="industry-label"
                value={newCompanyIndustry}
                onChange={(e) => setNewCompanyIndustry(e.target.value)}
              >
                {industryOptions.map((industry) => (
                  <MenuItem key={industry} value={industry}>
                    {industry}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel id="billing-tier-label">Billing Tier</InputLabel>
              <Select
                labelId="billing-tier-label"
                value={newCompanyBillingTier}
                onChange={(e) => setNewCompanyBillingTier(e.target.value)}
              >
                {billingTierOptions.map((tier) => (
                  <MenuItem key={tier} value={tier}>
                    {tier}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => setIsCreateDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleCreateCompany} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default CompanyListing;
