import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import dashboard from '../assets/dashboard.png';
import faq from '../assets/faq.png';
import leads from '../assets/leads.png';
import map from '../assets/map.png';
import reports from '../assets/reports.png';
import users from '../assets/users.png';
import '../HomePage.css';

const CarouselContainer = styled('div')({
  width: '100%',
  height: '1000px', // Set a fixed height for the carousel
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
});

const CarouselImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain', // This will maintain aspect ratio
  maxWidth: '1500px', // Set a max-width if needed
  transition: 'opacity 0.5s ease-in-out',
});

const ImageCarousel = () => {
  const images = [dashboard, faq, leads, map, reports, users];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedImage, setDisplayedImage] = useState(images[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    // Preload the next image
    const nextImage = new Image();
    nextImage.src = images[currentIndex];
    nextImage.onload = () => {
      setDisplayedImage(images[currentIndex]);
    };
  }, [currentIndex, images]);

  return (
    <CarouselContainer>
      <CarouselImage src={displayedImage} alt={`carousel-${currentIndex}`} />
    </CarouselContainer>
  );
};

export default ImageCarousel;