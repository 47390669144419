import React, { useState, useEffect } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { updateUserDetails, sendPasswordReset, deactivateUser, fetchCompanies } from './Firebase';

const UserEditForm = ({ user, onClose, onSave, currentUser }) => {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [username, setUsername] = useState(user.username);
  const [phone, setPhone] = useState(user.phone);
  const [companyUmbrella, setCompanyUmbrella] = useState(user.companyUmbrella);
  const [role, setRole] = useState(user.role);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const companyList = await fetchCompanies();
        setCompanies(companyList);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanyData();
  }, []);

  const handleSave = async () => {
    const updatedUser = { ...user, name, email, username, phone, companyUmbrella, role };
    try {
      await updateUserDetails(updatedUser);
      onSave(updatedUser);
      onClose();
    } catch (error) {
      console.error('Error updating user details:', error);
      alert('Failed to update user details');
    }
  };

  const handleSendResetPassword = async () => {
    try {
      await sendPasswordReset(email);
      alert('Password reset link sent.');
    } catch (error) {
      console.error('Error sending reset password link:', error);
      alert('Failed to send reset password link');
    }
  };

  const handleDeactivateUser = async () => {
    try {
      await deactivateUser(user.id);
      alert('User deactivated.');
      onClose();
    } catch (error) {
      console.error('Error deactivating user:', error);
      alert('Failed to deactivate user');
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Username"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Phone"
          fullWidth
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          margin="normal"
        />
        {currentUser.role === 'Super Admin' ? (
          <TextField
            label="Company Umbrella"
            select
            fullWidth
            value={companyUmbrella}
            onChange={(e) => setCompanyUmbrella(e.target.value)}
            margin="normal"
          >
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.name}>
                {company.name}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            label="Company Umbrella"
            fullWidth
            value={companyUmbrella}
            margin="normal"
            disabled
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSendResetPassword} color="primary">
          Send Reset Password
        </Button>
        <Button onClick={handleDeactivateUser} color="secondary">
          Deactivate User
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserEditForm;
