import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ref, onValue, push, set, update, get } from 'firebase/database';
import { Box, Typography, TextField, Button, FormControl, Select, MenuItem } from '@mui/material';
import { database } from './Firebase';

const CustomerForm = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const companyRef = ref(database, `companies/${companyId}`);
    onValue(companyRef, (snapshot) => {
      const companyData = snapshot.val();
      if (companyData) {
        setCompany(companyData);
      }
    });
  }, [companyId]);

  const handleInputChange = (fieldId, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: value,
    }));
  };

  const mapFormValuesToLead = (formValues, fieldMappings) => {
    const mappedValues = {};
    for (const [fieldId, fieldValue] of Object.entries(formValues)) {
      if (fieldMappings[fieldId]) {
        mappedValues[fieldMappings[fieldId]] = fieldValue;
      }
    }
    return mappedValues;
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const fieldMappings = {
      '055ea0e5-1edd-48b3-afda-322f3e16ff0a': 'state',
      '346e70b9-bc99-43da-95ac-d80605a277c7': 'timeFrame', // Example mapping, adjust as needed
      '38004df3-9c2f-4171-8c0a-83f462b63193': 'city',
      '561f976d-e2a3-491e-9b62-83a91e82959f': 'yesNoField', // Adjust as needed
      '65fa4983-80fc-4034-bba5-8f01b480c3f9': 'name',
      '6cd3393a-aef1-4df8-a531-60d863068577': 'zip', // Adjust as needed
      'a4bc2386-3373-4aed-8452-82b6223edbdb': 'street',
      'c1d59b26-59a6-499d-936a-6dcd90e60729': 'email',
      'd641630f-ab88-4ac5-b442-5c761e92abd2': 'yesNoField2', // Adjust as needed
      'f6259c4e-d3d9-45d0-a6cd-a443e5a484a0': 'phone'
    };

    try {
      // Map form values to lead fields
      const mappedFormValues = mapFormValuesToLead(formValues, fieldMappings);

      console.log('Mapped Form Values:', mappedFormValues);

      // Check for existing leads
      const leadsRef = ref(database, 'leads');
      const snapshot = await get(leadsRef);
      const existingLeads = snapshot.val() || {};

      console.log('Existing Leads:', existingLeads);

      let existingLeadId = null;
      let maxMatches = 0;

      for (const [id, lead] of Object.entries(existingLeads)) {
        if (lead && lead.company === company.formName) {
          let matchCount = 0;
          const fieldsToCheck = ['name', 'email', 'phone', 'city', 'state', 'street']; // Adjust as needed

          fieldsToCheck.forEach((field) => {
            if (lead[field] && mappedFormValues[field] && lead[field].toLowerCase() === mappedFormValues[field].toLowerCase()) {
              matchCount++;
            }
          });

          if (matchCount > maxMatches) {
            maxMatches = matchCount;
            existingLeadId = id;
          }
        }
      }

      console.log('Max Matches:', maxMatches);
      console.log('Existing Lead ID:', existingLeadId);

      if (maxMatches >= 3) { // Example threshold, adjust as needed
        const existingLeadRef = ref(database, `leads/${existingLeadId}`);
        await update(existingLeadRef, {
          formValues: { ...existingLeads[existingLeadId].formValues, ...mappedFormValues },
          updatedAt: new Date().toISOString(),
        });
      } else {
        const newSubmissionRef = push(ref(database, 'submissions'));
        await set(newSubmissionRef, {
          companyId,
          formValues: mappedFormValues,
          timestamp: new Date().toISOString(),
        });
      }

      navigate('/thank-you');
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!company) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box p={3}>
      <Typography variant="h4" align="center">{company.formName}</Typography>
      {company.logoUrl && <img src={company.logoUrl} alt="Company Logo" style={{ maxWidth: '200px', maxHeight: '200px', display: 'block', margin: '0 auto 20px auto' }} />}
      <Typography variant="h6" align="center">{company.slogan}</Typography>
      <Typography variant="body1" align="center" gutterBottom>{company.description}</Typography>
      {company.formFields.map((field) => (
        <Box key={field.id} mt={2}>
          <Typography variant="subtitle1">{field.question}</Typography>
          {field.type === 'text' ? (
            <TextField
              fullWidth
              variant="outlined"
              onChange={(e) => handleInputChange(field.id, e.target.value)}
            />
          ) : (
            <FormControl fullWidth>
              <Select
                defaultValue=""
                onChange={(e) => handleInputChange(field.id, e.target.value)}
              >
                {field.options.map((option, index) => (
                  <MenuItem key={index} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      ))}
      <Button
        variant="contained"
        color="primary"
        sx={{ marginTop: '20px' }}
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </Button>
    </Box>
  );
};

export default CustomerForm;
