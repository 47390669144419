import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography, Divider, Button, TextField, Modal } from '@mui/material';
import Sidebar from './Sidebar';
import CreateUserForm from './CreateUserForm';
import UserEditForm from './UserEditForm';
import { fetchUsersFromFirestore, getCurrentUser } from './Firebase';

const UserListing = () => {
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showCreateUserForm, setShowCreateUserForm] = useState(false);
  const [showEditUserForm, setShowEditUserForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await getCurrentUser();
        setCurrentUser(user);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching current user:', error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const fetchUsers = async () => {
    if (!currentUser) {
      console.error('Current user is not defined');
      return;
    }

    try {
      const allUsers = await fetchUsersFromFirestore(currentUser);
      setUsers(allUsers);
      setFilteredUsers(allUsers);
      setSearchPerformed(true);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleUserCreated = () => {
    fetchUsers();
    setShowCreateUserForm(false);
  };

  const toggleCreateUserForm = () => {
    setShowCreateUserForm(!showCreateUserForm);
  };

  const handleSearch = () => {
    if (currentUser) {
      fetchUsers();
    }
  };

  const handleSearchInput = (e) => {
    setSearchQuery(e.target.value);
    if (searchPerformed) {
      const query = e.target.value.toLowerCase();
      const filtered = users.filter(user =>
        user.email.toLowerCase().includes(query) ||
        user.username.toLowerCase().includes(query) ||
        user.name.toLowerCase().includes(query) ||
        user.phone.toLowerCase().includes(query)
      );
      setFilteredUsers(filtered);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setShowEditUserForm(true);
  };

  const handleUserSave = (updatedUser) => {
    setUsers(users.map(u => (u.id === updatedUser.id ? updatedUser : u)));
    setFilteredUsers(filteredUsers.map(u => (u.id === updatedUser.id ? updatedUser : u)));
    setShowEditUserForm(false);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!currentUser) {
    return <Typography>User not authenticated</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh', backgroundColor: theme.palette.background.default }}>
      <Sidebar />
      <Box sx={{ flex: 1, padding: '20px', backgroundColor: theme.palette.background.default }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            marginTop: '20px',
          }}
        >
          User Listing
        </Typography>
        <Divider sx={{ marginBottom: '20px' }} />
        <Box display="flex" alignItems="center">
          <TextField
            label="Search Users"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchInput}
            sx={{ marginRight: '20px', flex: 1 }}
          />
          <Button
            onClick={handleSearch}
            variant="contained"
            color="primary"
            sx={{ marginRight: '20px' }}
          >
            Search
          </Button>
          {currentUser.role !== 'User' && (
            <Button
              onClick={toggleCreateUserForm}
              variant="contained"
              color="secondary"
            >
              Create User
            </Button>
          )}
        </Box>
        <Modal
          open={showCreateUserForm}
          onClose={toggleCreateUserForm}
          aria-labelledby="create-user-modal-title"
          aria-describedby="create-user-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            
            <CreateUserForm onUserCreated={handleUserCreated} currentUser={currentUser} />
          </Box>
        </Modal>
        {showEditUserForm && selectedUser && (
          <UserEditForm user={selectedUser} onSave={handleUserSave} onClose={() => setShowEditUserForm(false)} currentUser={currentUser} />
        )}
        <Grid container spacing={2} sx={{ marginTop: '20px', backgroundColor: theme.palette.background.paper }}>
          {filteredUsers.map(user => (
            <Grid item xs={12} key={user.id}>
              <Box
                p={2}
                bgcolor={theme.palette.background.default }
                boxShadow={1}
                borderRadius={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body1">{user.name}</Typography>
                <Typography variant="body1">{user.email}</Typography>
                <Button variant="contained" color="primary" onClick={() => handleUserClick(user)}>
                  Edit
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default UserListing;
