import { collection, doc, setDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { db } from './Firebase';

const logEvent = async (userId, action, details = {}) => {
  try {
    const logId = `${userId}_${new Date().toISOString()}`;
    const logRef = doc(db, 'logs', logId);
    const logSnap = await getDoc(logRef);

    if (!logSnap.exists()) {
      await setDoc(logRef, {
        userId,
        action,
        details,
        timestamp: serverTimestamp(),
      });
      console.log('Event logged successfully.');
    } else {
      console.log('Document already exists, skipping creation.');
    }
  } catch (error) {
    console.error('Error logging event:', error);
  }
};

export default logEvent;
