import React, { useEffect, useRef, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Sidebar from './Sidebar';
import { Box } from '@mui/material';
import { ref, onValue } from 'firebase/database';
import { getCurrentUser, database } from './Firebase';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import { renderToString } from 'react-dom/server';

const Map = () => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [leads, setLeads] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const initializeMap = () => {
      if (!mapRef.current && mapContainerRef.current) {
        mapRef.current = L.map(mapContainerRef.current).setView([38.61, -105.36], 13);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { noWrap: true,
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(mapRef.current);
      }
    };

    initializeMap();

    const fetchLeads = async () => {
      const user = await getCurrentUser();
      setCurrentUser(user);

      const leadsRef = ref(database, 'leads');
      onValue(leadsRef, (snapshot) => {
        const data = snapshot.val();
        const leadsArray = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];

        const filteredLeads = leadsArray.filter(lead => {
          if (user.role === 'Super Admin') {
            return true;
          } else if (user.role === 'Owner' && lead.company === user.companyUmbrella) {
            return true;
          } else if (user.role === 'User' && lead.userAssigned === user.name) {
            return true;
          }
          return false;
        });

        setLeads(filteredLeads);

        // Set the map view to the most recent lead's coordinates
        if (filteredLeads.length > 0 && mapRef.current) {
          const mostRecentLead = filteredLeads[filteredLeads.length - 1];
          if (mostRecentLead.latitude && mostRecentLead.longitude) {
            mapRef.current.setView([mostRecentLead.latitude, mostRecentLead.longitude], 13);
          }
        }
      });
    };

    fetchLeads();

    // Cleanup function to remove the map instance on component unmount
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (mapRef.current && leads.length > 0) {
      // Clear existing markers
      mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          mapRef.current.removeLayer(layer);
        }
      });

      // Create a data URL from the MUI icon
      const iconString = renderToString(<PersonPinCircleIcon style={{ color: 'red', fontSize: 40 }} />);
      const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">${iconString}</svg>`;
      const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;

      // Custom icon for markers
      const customIcon = L.icon({
        iconUrl: iconUrl,
        iconSize: [40, 40], // size of the icon
        iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -40], // point from which the popup should open relative to the iconAnchor
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        shadowSize: [40, 40] // size of the shadow
      });

      // Add new markers for the leads
      leads.forEach((lead) => {
        if (lead.latitude && lead.longitude) {
          L.marker([lead.latitude, lead.longitude], { icon: customIcon })
            .addTo(mapRef.current)
            .bindPopup(`<b>${lead.name}</b><br>${lead.street}, ${lead.state}`);
        }
      });
    }
  }, [leads]);

  return (
    <>
      <Box sx={{ display: 'flex', height: '100vh' }}>
        <Sidebar />
        <Box sx={{ flex: 1, padding: '20px', paddingLeft: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }}></div>
        </Box>
      </Box>
      <Box sx={{ flex: 1, padding: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h3>*NOTE - Lead pins may not be in the right location, double check the information and look up the address separately to confirm!*</h3>
      </Box>
    </>
  );
};

export default Map;
