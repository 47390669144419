import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const EditLeadModal = ({ open, onClose, lead, companies, users, onSave }) => {
    const [leadData, setLeadData] = useState(lead);

    useEffect(() => {
        
        setLeadData(lead);
    }, [lead]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLeadData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            await onSave(leadData);
            onClose();
        } catch (error) {
            console.error('Error updating lead:', error);
        }
    };

    const states = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
        'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts',
        'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
        'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
        'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia',
        'Wisconsin', 'Wyoming'
    ];

    // Ensure companies and users are defined before using them
    const renderCompanies = () => {
        if (!companies) return null; // Handle cases where companies is null or undefined
        return companies.map((company) => (
            <MenuItem key={company.id} value={company.name}>
                {company.name}
            </MenuItem>
        ));
    };

    const renderUsers = () => {
        if (!leadData?.company || !users[leadData?.company]) return null; // Handle cases where company or users for the company are undefined
        return users[leadData.company].map((user) => (
            <MenuItem key={user.id} value={user.name}>
                {user.name}
            </MenuItem>
        ));
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ ...modalStyle }}>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <TextField name="name" label="Name" value={leadData?.name || ''} onChange={handleChange} fullWidth style={{ marginRight: '10px' }}/>
                    <TextField name="email" label="Email" value={leadData?.email || ''} onChange={handleChange} fullWidth />
                </div>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <TextField name="phone" label="Phone" value={leadData?.phone || ''} onChange={handleChange} fullWidth style={{ marginRight: '10px' }}/>
                    <TextField name="street" label="Street Address" value={leadData?.street || ''} onChange={handleChange} fullWidth />
                </div>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <FormControl fullWidth style={{ marginRight: '10px' }}>
                        <InputLabel>State</InputLabel>
                        <Select name="state" value={leadData?.state || ''} onChange={handleChange}>
                            {states.map((state) => (
                                <MenuItem key={state} value={state}>
                                    {state}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField name="city" label="City" value={leadData?.city || ''} onChange={handleChange} fullWidth />
                </div>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <TextField name="zipCode" label="Zip Code" value={leadData?.zip || ''} onChange={handleChange} fullWidth style={{ marginRight: '10px' }}/>
                    <FormControl fullWidth>
                        <InputLabel>Company</InputLabel>
                        <Select name="company" value={leadData?.company || ''} onChange={handleChange}>
                            {renderCompanies()}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <FormControl fullWidth style={{ marginRight: '10px' }}>
                        <InputLabel>User</InputLabel>
                        <Select name="userAssigned" value={leadData?.userAssigned || ''} onChange={handleChange}>
                            {renderUsers()}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Lead Source</InputLabel>
                        <Select name="leadSource" value={leadData?.leadSource || ''} onChange={handleChange}>
                            <MenuItem value="Direct">Direct</MenuItem>
                            <MenuItem value="Ads">Ads</MenuItem>
                            <MenuItem value="Referral">Referral</MenuItem>
                            <MenuItem value="Social Media">Social Media</MenuItem>
                            <MenuItem value="Events">Events</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <FormControl fullWidth>
                        <InputLabel>Lead Cycle</InputLabel>
                        <Select name="leadCycle" value={leadData?.leadCycle || ''} onChange={handleChange}>
                            <MenuItem value="New Lead">New Lead</MenuItem>
                            <MenuItem value="Enrolled">Enrolled</MenuItem>
                            <MenuItem value="Qualified">Qualified</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button variant="contained" onClick={handleSave}>Save</Button>
                    <Button variant="contained" onClick={onClose}>Cancel</Button>
                </Box>
            </Box>
        </Modal>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default EditLeadModal;
