import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableRow, Paper } from '@mui/material';

const LeadEnrolledComparison = ({ lead, submission }) => {
  const formFieldsMapping = {
    "65fa4983-80fc-4034-bba5-8f01b480c3f9": "Name",
    "055ea0e5-1edd-48b3-afda-322f3e16ff0a": "State",
    "346e70b9-bc99-43da-95ac-d80605a277c7": "Lead Time",
    "38004df3-9c2f-4171-8c0a-83f462b63193": "City",
    "561f976d-e2a3-491e-9b62-83a91e82959f": "Interested",
    "6cd3393a-aef1-4df8-a531-60d863068577": "Zip Code",
    "a4bc2386-3373-4aed-8452-82b6223edbdb": "Street Address",
    "c1d59b26-59a6-499d-936a-6dcd90e60729": "Email",
    "d641630f-ab88-4ac5-b442-5c761e92abd2": "Subscribed",
    "f6259c4e-d3d9-45d0-a6cd-a443e5a484a0": "Phone Number",
  };

  const leadFields = [
    { label: 'ID', value: lead.id },
    { label: 'City', value: lead.city },
    { label: 'Company', value: lead.company },
    { label: 'Created At', value: lead.createdAt },
    { label: 'Email', value: lead.email },
    { label: 'Latitude', value: lead.latitude },
    { label: 'Lead Cycle', value: lead.leadCycle },
    { label: 'Lead Source', value: lead.leadSource },
    { label: 'Longitude', value: lead.longitude },
    { label: 'Name', value: lead.name },
    { label: 'Phone', value: lead.phone },
    { label: 'State', value: lead.state },
    { label: 'Street', value: lead.street },
    { label: 'User Assigned', value: lead.userAssigned },
    { label: 'Zip', value: lead.zip },
  ];

  const renderSubmissionField = (key, value) => {
    const label = formFieldsMapping[key] || key;
    return (
      <TableRow key={key}>
        <TableCell>{label}</TableCell>
        <TableCell>{value}</TableCell>
      </TableRow>
    );
  };

  return (
    <Box p={2}>
      <Typography variant="h6">Assign Form</Typography>
      <Box display="flex" gap={2}>
        <Paper variant="outlined" sx={{ flex: 1 }}>
          <Typography variant="subtitle1" p={2}>Lead Details</Typography>
          <Table>
            <TableBody>
              {leadFields.map((field, index) => (
                <TableRow key={index}>
                  <TableCell>{field.label}</TableCell>
                  <TableCell>{field.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        <Paper variant="outlined" sx={{ flex: 1 }}>
          <Typography variant="subtitle1" p={2}>Form Submission Details</Typography>
          <Table>
            <TableBody>
              {Object.entries(submission.formValues).map(([key, value]) =>
                renderSubmissionField(key, value)
              )}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </Box>
  );
};

export default LeadEnrolledComparison;
