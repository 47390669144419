import { database, auth, db } from '../components/Firebase';
import { ref, get, set, update, remove, push } from 'firebase/database';
import { getDoc, doc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

export const getCurrentUser = async () => {
    const user = auth.currentUser;
    if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
            return { ...userDoc.data(), id: user.uid };
        } else {
            throw new Error('User document does not exist');
        }
    } else {
        throw new Error('User not authenticated');
    }
};

export const fetchIntegrations = async (companyId) => {
    const integrationsRef = ref(database, `integrations/${companyId}`);
    const snapshot = await get(integrationsRef);
    return snapshot.exists() ? snapshot.val() : {};
};

export const addIntegration = async (companyId, integrationData) => {
    const integrationsRef = ref(database, `integrations/${companyId}`);
    const newIntegrationRef = push(integrationsRef);
    await set(newIntegrationRef, integrationData);
    return newIntegrationRef.key;
};

export const updateIntegration = async (companyId, integrationId, integrationData) => {
    const integrationRef = ref(database, `integrations/${companyId}/${integrationId}`);
    await update(integrationRef, integrationData);
};

export const deleteIntegration = async (companyId, integrationId) => {
    const integrationRef = ref(database, `integrations/${companyId}/${integrationId}`);
    await remove(integrationRef);
};

export const pauseIntegration = async (companyId, integrationId, isPaused) => {
    const integrationRef = ref(database, `integrations/${companyId}/${integrationId}`);
    await update(integrationRef, { paused: isPaused });
};

export const generateApiKey = () => {
    return `${uuidv4()}-${Math.random().toString(36).substr(2, 9)}`;
};

export const fetchApiKeys = async (companyId) => {
    const apiKeysRef = ref(database, `apiKeys/${companyId}`);
    const snapshot = await get(apiKeysRef);
    return snapshot.exists() ? snapshot.val() : {};
};

export const addApiKey = async (companyId, apiKeyData) => {
    const apiKeysRef = ref(database, `apiKeys/${companyId}`);
    const newApiKeyRef = push(apiKeysRef);
    await set(newApiKeyRef, apiKeyData);
    return newApiKeyRef.key;
};
