import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import { renderToString } from 'react-dom/server';

const CustomerDetailsModal = ({ open, onClose, customer }) => {
  const map = useRef(null); // Reference to the map instance
  const mapContainerRef = useRef(null);
  const [hasMapContainer, setHasMapContainer] = useState(false); // State for map container existence

  // Function to initialize Leaflet map
  const initializeMap = () => {
    if (mapContainerRef.current && !map.current && customer && customer.latitude && customer.longitude) {
      map.current = L.map(mapContainerRef.current).setView([customer.latitude, customer.longitude], 13);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map.current);

      const customerIcon = L.divIcon({
        html: renderToString(<PersonPinCircleIcon style={{ color: 'red' }} />),
        className: 'customer-icon',
        iconSize: [24, 24],
        iconAnchor: [12, 24],
      });

      L.marker([customer.latitude, customer.longitude], { icon: customerIcon }).addTo(map.current);
    }
  };

  useEffect(() => {
    // Set map container existence flag when modal opens
    if (open) {
      setHasMapContainer(true);
    } else {
      setHasMapContainer(false);
      // Cleanup map instance if modal closes
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    }
  }, [open]);

  useEffect(() => {
    // Initialize map when modal opens, customer data is available, and container exists
    if (open && customer && customer.latitude && customer.longitude && hasMapContainer) {
      initializeMap();
    }
  }, [open, customer, hasMapContainer]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 4,
          outline: 'none', // Remove default focus outline
          maxHeight: '90vh', // Limit height and make modal scrollable if content exceeds
          overflowY: 'auto', // Ensure modal content is scrollable if needed
        }}
      >
        {customer && (
          <Box>
            <Typography variant="h5" align="center" gutterBottom>
              Customer Details
            </Typography>
            <Typography variant="body1">
              <strong>Name:</strong> {customer.name}
            </Typography>
            <Typography variant="body1">
              <strong>Company:</strong> {customer.company}
            </Typography>
            <Typography variant="body1">
              <strong>Address:</strong> {customer.street}, {customer.city}, {customer.state} {customer.zip}
            </Typography>
            <Typography variant="body1">
              <strong>Assigned User:</strong> {customer.userAssigned}
            </Typography>
            <Typography variant="body1">
              <strong>Created At:</strong> {new Date(customer.createdAt).toLocaleDateString()}
            </Typography>
            {/* Render map container */}
            <Box mt={2} ref={mapContainerRef} style={{ height: '300px', width: '100%' }}></Box>
            <Button variant="contained" color="primary" fullWidth onClick={onClose} sx={{ mt: 2 }}>
              Close
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default CustomerDetailsModal;
