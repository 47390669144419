import { createTheme } from '@mui/material/styles';

const getTheme = (mode) => createTheme({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // Light mode
          primary: {
            main: '#c7ecee',
          },
          secondary: {
            main: '#e8b77b',
          },
          tertiary: {
            main: '#d680ab',
          },
          qualifiedLight: {
            main: '#e8f5e9',
            contrastText: '#000000',
          },
          background: {
            default: '#f0f2f5',
            paper: '#ffffff',
          },
        }
      : {
          // Dark mode
          primary: {
            main: '#c7ecee',
          },
          secondary: {
            main: '#e8b77b',
          },
          tertiary: {
            main: '#d680ab',
          },
          qualifiedDark: {
            main: '#0a170b',
            contrastText: '#ffffff',
          },
          background: {
            default: '#121212',
            paper: '#1e1e1e',
          },
        }),
  },
});

export default getTheme;