import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  IconButton,
  Collapse,
  Paper,
  Switch,
  FormControlLabel
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Folder as FolderIcon,
  Assessment as AssessmentIcon,
  People as PeopleIcon,
  Business as BusinessIcon,
  AccountCircle as ProfileIcon,
  Settings as SettingsIcon,
  Help as FAQIcon,
  Logout as LogoutIcon,
  Map as MapIcon,
  Inventory as InventoryIcon,
  Webhook as WebhookIcon,
  Receipt as ReceiptIcon,
  Pending as PendingIcon,
  Menu as MenuIcon,
  ExpandLess,
  ExpandMore
} from '@mui/icons-material';
import { auth } from './Firebase';
import { UserContext } from './UserContext';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import { ColorModeContext } from '../App';
import axios from 'axios';

import LightLogo from '../assets/SetterSuiteLightThemeLogo.png';
import DarkLogo from '../assets/SetterSuiteDarkThemeLogo.png';
import LightLogoNoWords from '../assets/SetterSuiteLightThemeLogoNoWords.png';
import DarkLogoNoWords from '../assets/SetterSuiteDarkThemeLogoNoWords.png';

const StyledPaper = styled(Paper)`
  background-color: ${props => props.theme.palette.mode === 'dark' ? props.theme.palette.background.paper : '#c7ecee'};
  height: 100vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }
  
  &::-webkit-scrollbar-track {
    background: ${props => props.theme.palette.mode === 'dark' ? '#333' : '#f1f1f1'};
    border-radius: 8px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.palette.mode === 'dark' ? '#555' : '#888'};
    border-radius: 8px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.palette.mode === 'dark' ? '#777' : '#555'};
  }
`;

const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const SidebarText = styled.div`
  flex: 1;
`;

const TabContainer = styled.div`
  position: fixed;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  z-index: 999;
  padding: 5px;
`;

const CollapsedTab = styled.div`
  position: fixed;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #c7ecee;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  z-index: 999;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;
const Sidebar = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { user, clearUser } = useContext(UserContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showTab, setShowTab] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      setShowTab(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const toggleAdminMenu = () => {
    setAdminOpen(!adminOpen);
  };

  const handleLogout = async () => {
    try {
      const currentUser = auth.currentUser;
      const apiUrl = process.env.REACT_APP_API_URL;
      if (currentUser) {
        await axios.post(`${apiUrl}/logout`, { userId: currentUser.uid });
      }
      await auth.signOut();
      clearUser();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (user) {
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  };

  const sidebarItems = [
    { name: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
    { name: 'Map', icon: <MapIcon />, path: '/map' },
    { name: 'Leads', icon: <FolderIcon />, path: '/leads' },
    user?.role === 'Super Admin' && { name: 'Enrolled', icon: <PendingIcon />, path: '/enrolled' },
    user?.role !== 'User' && { name: 'Reports', icon: <AssessmentIcon />, path: '/reports' },
    user?.role !== 'User' && { name: 'Users', icon: <PeopleIcon />, path: '/users' },
    user?.role === 'Super Admin' && { name: 'Companies', icon: <BusinessIcon />, path: '/companies' },
    { name: 'Profile', icon: <ProfileIcon />, path: '/profile' },
    user?.role === 'Super Admin' && { name: 'Archived', icon: <InventoryIcon />, path: '/archived' },
    user?.role === 'Super Admin' && {
      name: 'Admin Settings',
      icon: <SettingsIcon />,
      subItems: [
        { name: 'Home Scheduling', path: '/admin-settings/home-scheduling' },
        { name: 'API Integrations', path: '/admin-settings/api-integrations' },
        { name: 'Company Credits', path: '/admin-settings/company-credits' }
      ]
    },
    user?.role === 'Super Admin' && { name: 'Integrations', icon: <WebhookIcon />, path: '/integrations' },
    user?.role === 'Super Admin' && { name: 'Billing', icon: <ReceiptIcon />, path: '/billing' },
    { name: 'Logout', icon: <LogoutIcon />, onClick: handleLogout }
  ].filter(Boolean);

  const logoSrc = theme.palette.mode === 'light' ? LightLogo : DarkLogo;
  const logoSrcNoWords = theme.palette.mode === 'light' ? LightLogoNoWords : DarkLogoNoWords;


  return (
    <>
    
      {showTab ? (
        <CollapsedTab onClick={toggleDrawer}>
          <MenuIcon />
        </CollapsedTab>
      ) : (
        <TabContainer>
          <IconButton onClick={toggleDrawer} size="large">
            <MenuIcon fontSize="large" />
          </IconButton>
          <a href="#" onClick={handleLogoClick}>
            <img
              src={logoSrc}
              alt="Logo"
              style={{
                width: '100%',
                height: '100px',
                marginLeft: location.pathname === '/map' ? '60px' : '10px',
              }}
            />
          </a>
        </TabContainer>
      )}
      <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer}>
      <StyledPaper theme={theme}>
          <LogoContainer>
          <a href="#" onClick={handleLogoClick}>
              <img
                src={logoSrcNoWords}
                alt="Logo"
                style={{
                  width: '100%',
                  height: '150px',
                }}
              />
            </a>
          </LogoContainer>
          <FormControlLabel
        control={
          <Switch
            checked={theme.palette.mode === 'dark'}
            onChange={colorMode.toggleColorMode}
            color="default"
          />
        }
        label="Mode"
      />
          <List>
            {sidebarItems.map((item) => (
              <React.Fragment key={item.name}>
                {item.subItems ? (
                  <>
                    <SidebarItem>
                      <ListItemButton onClick={toggleAdminMenu}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <SidebarText>
                          <ListItemText primary={item.name} />
                        </SidebarText>
                        {adminOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </SidebarItem>
                    <Collapse in={adminOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.subItems.map((subItem) => (
                          <ListItemButton
                            key={subItem.name}
                            component={RouterLink}
                            to={subItem.path}
                            selected={location.pathname === subItem.path}
                            sx={{ pl: 4 }}
                          >
                            <ListItemText primary={subItem.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  <SidebarItem>
                    <ListItemButton
                      component={item.onClick ? 'button' : RouterLink}
                      to={item.path}
                      onClick={item.onClick}
                      selected={location.pathname === item.path}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <SidebarText>
                        <ListItemText primary={item.name} />
                      </SidebarText>
                    </ListItemButton>
                  </SidebarItem>
                )}
              </React.Fragment>
            ))}
          </List>
        </StyledPaper>
      </Drawer>
    </>
  );
};

export default Sidebar;