// src/services/AdminIntegrationService.js

import { ref, push, set, get, remove, update } from 'firebase/database';
import { database } from '../components/Firebase'; // Adjust this import path to where your Firebase config is

export const fetchAdminIntegrations = async (companyId) => {
  const snapshot = await get(ref(database, `integrations/${companyId}`));
  const integrations = snapshot.val();
  return integrations 
    ? Object.keys(integrations).map(key => ({
        id: key,
        ...integrations[key]
      }))
    : [];
};

export const addAdminIntegration = async (integrationData) => {
  const { companyId, ...data } = integrationData;
  const newIntegrationRef = push(ref(database, `integrations/${companyId}`));
  await set(newIntegrationRef, data);
  return { id: newIntegrationRef.key, ...data };
};

export const updateAdminIntegration = async (companyId, integrationId, updatedData) => {
  const integrationRef = ref(database, `integrations/${companyId}/${integrationId}`);
  await update(integrationRef, updatedData);
  return { id: integrationId, ...updatedData };
};

export const deleteAdminIntegration = async (companyId, integrationId) => {
  const integrationRef = ref(database, `integrations/${companyId}/${integrationId}`);
  await remove(integrationRef);
};