import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Paper, Typography, Divider, Button, Select, MenuItem, Checkbox, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CSVLink } from 'react-csv';
import { ref, get, query, orderByChild, startAt, endAt } from 'firebase/database';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Sidebar from './Sidebar';
import { database, getCurrentUser } from './Firebase'; // Import getCurrentUser from Firebase

const ItemType = {
  COLUMN: 'column',
};

const DraggableColumn = ({ column, index, moveColumn }) => {
  const [, ref] = useDrag({
    type: ItemType.COLUMN,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType.COLUMN,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveColumn(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <TableCell ref={(node) => ref(drop(node))} style={{ cursor: 'move', display: 'flex', alignItems: 'center' }}>
      <DragIndicatorIcon style={{ marginRight: '8px' }} />
      {column.label}
    </TableCell>
  );
};

const Reports = () => {
  const theme = useTheme();
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedReport, setSelectedReport] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [selectedTimeframe, setSelectedTimeframe] = useState('');
  const [reportData, setReportData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [columns, setColumns] = useState([
    { id: 'id', label: 'Lead ID', visible: true },
    { id: 'name', label: 'Name', visible: true },
    { id: 'email', label: 'Email', visible: true },
    { id: 'leadSource', label: 'Lead Source', visible: true },
    { id: 'leadCycle', label: 'Lead Cycle', visible: true },
    { id: 'createdAt', label: 'Created At', visible: true },
    { id: 'userAssigned', label: 'Assigned User', visible: true },
    { id: 'company', label: 'Company', visible: true },
    { id: 'street', label: 'Street', visible: true },
    { id: 'zip', label: 'Zip', visible: true },
    { id: 'phone', label: 'Phone', visible: true },
  ]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await getCurrentUser();
        setCurrentUser(user); // Assuming getCurrentUser returns the user object correctly
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };
  
    fetchUserData();
  }, []); // Empty dependency array to run only once on mount
  
  const fetchReportData = async () => {
  try {
    if (!selectedReport || !selectedSubCategory || !selectedTimeframe) {
      console.warn('Skipping fetchReportData due to missing data:', { selectedReport, selectedSubCategory, selectedTimeframe });
      return;
    }

    const currentUser = await getCurrentUser();

    let leadsRef = ref(database, 'leads');
    const snapshot = await get(leadsRef);

    let startTime, endTime;
    const currentDate = new Date();

    switch (selectedTimeframe) {
      case 'today': {
        startTime = new Date(currentDate.setHours(0, 0, 0, 0)).toISOString();
        endTime = new Date(currentDate.setHours(23, 59, 59, 999)).toISOString();
        break;
      }
      case 'thisWeek': {
        const firstDayOfWeek = currentDate.getDate() - currentDate.getDay() + 1;
        startTime = new Date(currentDate.setDate(firstDayOfWeek)).toISOString();
        endTime = new Date(currentDate.setDate(firstDayOfWeek + 6)).toISOString();
        break;
      }
      case 'thisMonth': {
        startTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toISOString();
        endTime = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).toISOString();
        break;
      }
      case 'thisYear': {
        startTime = new Date(currentDate.getFullYear(), 0, 1).toISOString();
        endTime = new Date(currentDate.getFullYear() + 1, 0, 0).toISOString();
        break;
      }
      case 'allTime': {
        startTime = new Date(0).toISOString();
        endTime = new Date().toISOString();
        break;
      }
      default:
        break;
    }

    if (startTime && endTime) {
      leadsRef = query(leadsRef, orderByChild('createdAt'), startAt(startTime), endAt(endTime));
    }

    let filteredLeads = Object.values(snapshot.val());

    if (currentUser.role !== 'Super Admin') {
      filteredLeads = filteredLeads.filter((lead) => {
        if (currentUser.role === 'Owner') {
          return lead.company === currentUser.companyUmbrella;
        } else if (currentUser.role === 'User') {
          return lead.userAssigned === currentUser.email;
        }
        return false;
      });
    }

    filteredLeads = filteredLeads.filter((lead) => {
      if (selectedReport === 'leadSource') {
        return lead.leadSource === selectedSubCategory;
      } else if (selectedReport === 'leadCycle') {
        return lead.leadCycle === selectedSubCategory;
      }
      return false;
    });

    console.log('Filtered Leads:', filteredLeads);

    setReportData(filteredLeads);
    setCsvData(generateCSVData(filteredLeads));
  } catch (error) {
    console.error('Error fetching report data:', error);
  }
};


  const generateCSVData = (data) => {
    const visibleColumns = columns.filter(column => column.visible);
  

    const csvRows = data.map((item) => {
      let row = {};
      visibleColumns.forEach(column => {
        row[column.id] = item[column.id];
      });
      return row;
    });

    return csvRows;
  };

  const moveColumn = (fromIndex, toIndex) => {
    const updatedColumns = Array.from(columns);
    const [movedColumn] = updatedColumns.splice(fromIndex, 1);
    updatedColumns.splice(toIndex, 0, movedColumn);

    setColumns(updatedColumns);
  };

  const handleColumnVisibilityChange = (columnId) => {
    setColumns(columns.map(column =>
      column.id === columnId ? { ...column, visible: !column.visible } : column
    ));
  };

  if (!currentUser) {
    return <Typography variant="body1">Loading...</Typography>; // Display loading state while fetching user data
  }

  const tableHeaderCellStyle = {
    cursor: 'pointer',
    fontWeight: 'bold',
    backgroundColor: '#e0e0e0',
    '&:hover': {
        backgroundColor: '#f2f2f2',
    },
    padding: '10px',
};

  return (
    <Box sx={{ display: 'flex', height: '100vh', backgroundColor: theme.palette.background.default }}>
      <Sidebar />

      <Box sx={{ flex: 1, padding: '20px', backgroundColor: theme.palette.background.default }}>
      <Typography
  variant="h4"
  gutterBottom
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center', // Added textAlign to center the text horizontally
    marginTop: '20px', // Added marginTop to give some space between the text and the top
  }}
>
          Reports
        </Typography>
        <Divider sx={{ marginBottom: '20px' }} />

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <Select
              value={selectedReport}
              onChange={(e) => {
                setSelectedReport(e.target.value);
                setSelectedSubCategory('');
              }}
              displayEmpty
              fullWidth
              sx={{ marginBottom: '10px' }}
            >
              <MenuItem value="" disabled>
                Select Report
              </MenuItem>
              <MenuItem value="leadSource">Lead Source</MenuItem>
              <MenuItem value="leadCycle">Lead Cycle</MenuItem>
            </Select>

            {selectedReport && (
              <Select
                value={selectedSubCategory}
                onChange={(e) => setSelectedSubCategory(e.target.value)}
                displayEmpty
                fullWidth
                sx={{ marginBottom: '10px' }}
              >
                <MenuItem value="" disabled>
                  Select Sub-Category
                </MenuItem>
                {selectedReport === 'leadSource'
                  ? [
                      { label: 'Direct', value: 'Direct' },
                      { label: 'Ads', value: 'Ads' },
                      { label: 'Referral', value: 'Referral' },
                      { label: 'Social Media', value: 'Social Media' },
                      { label: 'Events', value: 'Events' },
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  : [
                      { label: 'New Lead', value: 'New Lead' },
                      { label: 'Enrolled', value: 'Enrolled' },
                      { label: 'Qualified', value: 'Qualified' },
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
              </Select>
            )}


            <Select
              value={selectedTimeframe}
              onChange={(e) => setSelectedTimeframe(e.target.value)}
              displayEmpty
              fullWidth
              sx={{ marginBottom: '10px' }}
            >
              <MenuItem value="" disabled>
                Select Date/Time
              </MenuItem>
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="thisWeek">This Week</MenuItem>
              <MenuItem value="thisMonth">This Month</MenuItem>
              <MenuItem value="thisYear">This Year</MenuItem>
              <MenuItem value="allTime">All Time</MenuItem>
            </Select>


            <Button
              variant="contained"
              color="primary"
              onClick={fetchReportData}
              disabled={!selectedReport || !selectedSubCategory || !selectedTimeframe}
              fullWidth
              sx={{ marginBottom: '10px' }}
            >
              Generate Report
            </Button>


            <CSVLink
              data={csvData}
              filename={`report-${selectedReport}-${selectedSubCategory}-${selectedTimeframe}.csv`}
              target="_blank"
              >
              <Button variant="contained" color="secondary" fullWidth>
                Export CSV
              </Button>
            </CSVLink>
          </Grid>
          <Grid item xs={12} md={6} sx={{textAlign: 'center' }}>
            <Typography variant="h6">Show Columns</Typography>
            {columns.map((column) => (
              <FormControlLabel
                key={column.id}
                control={
                  <Checkbox
                    checked={column.visible}
                    onChange={() => handleColumnVisibilityChange(column.id)}
                  />
                }
                label={column.label}
              />
            ))}
          </Grid>
        </Grid>


        <DndProvider backend={HTML5Backend}>
        <TableContainer component={Paper} sx={{ marginTop: '20px', backgroundColor: theme.palette.background.paper }} >
            <Table>
              <TableHead>
                <TableRow >
                  {columns.filter(column => column.visible).map((column, index) => (
                    <TableCell 
                    key={column.id} 
                    sx={{
                      ...tableHeaderCellStyle,
                      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
                      color: theme.palette.text.primary,
                    }}
                  >
                      <DraggableColumn
                        column={column}
                        index={index}
                        moveColumn={moveColumn}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>


              <TableBody>
                {reportData.map((row) => (
                  <TableRow key={row.id}>
                    {columns.filter(column => column.visible).map((column) => (
                      <TableCell key={column.id}>{row[column.id]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DndProvider>
      </Box>
    </Box>
  );
};


export default Reports;