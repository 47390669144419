import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Snackbar,
  Tabs,
  Tab
} from '@mui/material';
import { ref, get, update } from 'firebase/database';
import { database } from './Firebase';
import { useParams, useNavigate } from 'react-router-dom';
import EditCompanyCustomerForm from './EditCompanyCustomerForm'; // Import EditCompanyCustomerForm component

const industryOptions = ['Solar', 'Roofing', 'Alarms', 'Smart Systems', 'HVAC', 'Windows', 'Pest', 'Insurance'];
const billingTierOptions = ['M2M', '50+', '100+', '150+'];

const CompanyDetail = () => {
  const { id: uniqueUrl } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);
  const [name, setName] = useState('');
  const [industry, setIndustry] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [billingTier, setBillingTier] = useState('');

  useEffect(() => {
    if (!uniqueUrl) {
      console.error('No unique URL provided');
      return;
    }

    console.log(`Fetching company details for URL: ${uniqueUrl}`);
    const fetchCompany = async () => {
      const companiesRef = ref(database, 'companies');
      const companiesSnapshot = await get(companiesRef);
      if (companiesSnapshot.exists()) {
        const companiesData = companiesSnapshot.val();
        const companyKey = Object.keys(companiesData).find(
          key => companiesData[key].uniqueUrl === uniqueUrl
        );

        if (companyKey) {
          const companyData = companiesData[companyKey];
          setCompany({ ...companyData, id: companyKey });
          setName(companyData.name || '');
          setIndustry(companyData.industry || '');
          setBillingTier(companyData.billingTier || '');
        } else {
          console.error('Company not found');
        }
      } else {
        console.error('Companies not found');
      }
    };

    fetchCompany();
  }, [uniqueUrl]);

  const handleSave = async () => {
    if (!company) return;

    const companyRef = ref(database, `companies/${company.id}`);
    const updatedCompany = {
      ...company,
      name,
      industry,
      billingTier
    };

    try {
      await update(companyRef, updatedCompany);
      setCompany(updatedCompany);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating company:', error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  if (!company) return <div>Loading...</div>;

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Box sx={{ flex: 1, padding: '20px', backgroundColor: '#f0f2f5' }}>
        <Button onClick={() => navigate('/companies')}>Back to Company List</Button>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="company detail tabs">
          <Tab label="Company Details" />
          <Tab label="Edit Form" />
        </Tabs>
        {tabIndex === 0 && (
          <Box>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              Company Details
            </Typography>
            <Divider />
            <Box mt={2}>
              <TextField
                label="Company ID"
                variant="outlined"
                fullWidth
                value={company.companyId}
                disabled
                sx={{ marginBottom: '20px' }}
              />
              <TextField
                label="Company Name"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ marginBottom: '20px' }}
              />
              <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                <InputLabel id="industry-label">Industry</InputLabel>
                <Select
                  labelId="industry-label"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                >
                  {industryOptions.map((industry) => (
                    <MenuItem key={industry} value={industry}>
                      {industry}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ marginBottom: '20px' }}>
            <InputLabel id="billing-tier-label">Billing Tier</InputLabel>
            <Select
              labelId="billing-tier-label"
              value={billingTier}
              onChange={(e) => setBillingTier(e.target.value)}
            >
              {billingTierOptions.map((tier) => (
                <MenuItem key={tier} value={tier}>
                  {tier}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Box>
          </Box>
        )}
        {tabIndex === 1 && <EditCompanyCustomerForm company={company} />}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          message="Company updated successfully!"
        />
      </Box>
    </Box>
  );
};

export default CompanyDetail;
