import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { fetchUsersFromFirestore, getCurrentUser, database } from './Firebase';
import { ref, get, set } from 'firebase/database';
import Sidebar from './Sidebar'; // Ensure the correct path

const HomeScheduling = () => {
  const [schedulingTypes] = useState(['First Available', 'Round Robin']);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [schedulingType, setSchedulingType] = useState('First Available');
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const cachedUsers = localStorage.getItem('cachedUsers');
        if (cachedUsers) {
          setUsers(JSON.parse(cachedUsers));
        } else {
          const currentUser = await getCurrentUser();
          const usersList = await fetchUsersFromFirestore(currentUser);
          const filteredUsers = usersList.filter(user => user.companyUmbrella === 'LeadLiquid');
          setUsers(filteredUsers);
          localStorage.setItem('cachedUsers', JSON.stringify(filteredUsers));
        }
      } catch (error) {
        setError(error.message);
        console.error('Error fetching users:', error);
      }
    };

    const fetchSelectedUsers = async () => {
      try {
        const snapshot = await get(ref(database, 'settings/scheduling/selectedUsers'));
        if (snapshot.exists()) {
          setSelectedUsers(snapshot.val());
        }
      } catch (error) {
        setError(error.message);
        console.error('Error fetching selected users:', error);
      }
    };

    fetchUsers();
    fetchSelectedUsers();
  }, []);

  const handleAddUser = (user) => {
    const updatedSelectedUsers = [...selectedUsers, user];
    setSelectedUsers(updatedSelectedUsers);
    saveSelectedUsers(updatedSelectedUsers);
    setShowUserDropdown(false);
  };

  const handleRemoveUser = (index) => {
    const updatedSelectedUsers = selectedUsers.filter((_, i) => i !== index);
    setSelectedUsers(updatedSelectedUsers);
    saveSelectedUsers(updatedSelectedUsers);
  };

  const saveSelectedUsers = async (users) => {
    try {
      await set(ref(database, 'settings/scheduling/selectedUsers'), users);
    } catch (error) {
      setError(error.message);
      console.error('Error saving selected users:', error);
    }
  };

  const handleSaveSettings = async () => {
    try {
      const settingsData = {
        schedulingType,
        selectedUsers: selectedUsers.map(user => ({
          id: user.id,
          name: user.name,
          email: user.email,
        }))
      };
      await set(ref(database, 'settings/scheduling'), settingsData);
      setSnackbarOpen(true);
    } catch (error) {
      setError(error.message);
      console.error('Error saving settings:', error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        <Typography variant="h5" gutterBottom>
          Home Page - Manage Scheduling
        </Typography>
        <Divider sx={{ margin: '20px' }} />
        <FormControl fullWidth sx={{ marginBottom: '20px' }}>
          <InputLabel id="scheduling-type-label">Scheduling Type</InputLabel>
          <Select
            labelId="scheduling-type-label"
            value={schedulingType}
            onChange={(e) => setSchedulingType(e.target.value)}
          >
            {schedulingTypes.map((type, index) => (
              <MenuItem key={index} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <List>
          {selectedUsers.map((user, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveUser(index)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={user.name} />
            </ListItem>
          ))}
        </List>
        {showUserDropdown && (
          <FormControl fullWidth sx={{ marginBottom: '20px' }}>
            <InputLabel id="user-dropdown-label">Select User</InputLabel>
            <Select
              labelId="user-dropdown-label"
              onChange={(e) => handleAddUser(users.find(user => user.uid === e.target.value))}
            >
              {users.map((user, index) => (
                <MenuItem key={index} value={user.uid}>{user.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Button variant="outlined" startIcon={<AddIcon />} onClick={() => setShowUserDropdown(true)}>
          Add User To Schedule
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveSettings}
        >
          Save Settings
        </Button>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          message="Settings saved successfully!"
        />
        {error && <Typography color="error">{error}</Typography>}
      </Box>
    </Box>
  );
};

export default HomeScheduling;
