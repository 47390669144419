import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import * as XLSX from 'xlsx';

const BulkUploadForm = ({ open, onClose, onBulkUpload }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const sanitizeKey = (key) => {
    return key.replace(/[.#$/[\]]/g, '_').trim().toLowerCase();
  };

  const processCSV = (csvData) => {
    const lines = csvData.split('\n').map(line => line.split(','));
    if (lines.length === 0) {
      console.error('Empty CSV file.');
      return [];
    }

    const headers = lines[0].map(header => sanitizeKey(header));
    return lines.slice(1).map(line => {
      const lead = {};
      line.forEach((value, index) => {
        const sanitizedKey = headers[index] || '';
        if (sanitizedKey) {
          lead[sanitizedKey] = value ? value.trim() : '';
        }
      });
      return lead;
    }).filter(lead => Object.keys(lead).length > 0);
  };

  const processXLSX = (data) => {
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const json = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    if (json.length === 0) {
      console.error('Empty XLSX file.');
      return [];
    }

    const headers = json[0].map(header => sanitizeKey(header));
    return json.slice(1).map(row => {
      const lead = {};
      row.forEach((value, index) => {
        const sanitizedKey = headers[index] || '';
        if (sanitizedKey) {
          lead[sanitizedKey] = value ? value.toString().trim() : '';
        }
      });
      return lead;
    }).filter(lead => Object.keys(lead).length > 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileType = file.type;
        let bulkLeads = [];

        if (fileType === 'text/csv') {
          bulkLeads = processCSV(event.target.result);
        } else if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          bulkLeads = processXLSX(event.target.result);
        } else {
          console.error('Unsupported file type:', fileType);
          return;
        }

        onBulkUpload(bulkLeads);
      };
      
      // Read as text for CSV and as array buffer for XLSX
      if (file.type === 'text/csv') {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }

      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Bulk Upload Leads</DialogTitle>
      <DialogContent>
        <h1>***HOW TO***</h1>
        <h3>- Download Template Below</h3>
        <h3>- Input Your Lead Data In The Correct Corresponding Columns</h3>
        <h3>- Then Export The FIle as XLSX or CSV</h3>
        <h3>- Then Upload The Template File You Exported With The Lead Data</h3>
        <form onSubmit={handleSubmit}>
          <Button
            href="/Bulk Lead Upload Template.csv"
            variant="outlined"
            color="primary"
            download
            style={{ margin: '10px 0' }}
          >
            Download Template
          </Button>
          <TextField
            fullWidth
            margin="normal"
            type="file"
            inputProps={{ accept: '.csv, .xlsx' }}
            onChange={handleFileChange}
            required
          />
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">
              Upload
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default BulkUploadForm;
