import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Select, MenuItem, CircularProgress } from '@mui/material';
import { fetchAdminIntegrations, addAdminIntegration, updateAdminIntegration, deleteAdminIntegration } from '../services/AdminIntegrationService';

const AdminIntegrations = ({ currentUser }) => {
  const [adminIntegrations, setAdminIntegrations] = useState([]);
  const [newAdminIntegration, setNewAdminIntegration] = useState({
    name: '',
    type: '',
    apiKey: '',
    shippoEventType: '',
    shippoEnvironment: 'test',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (currentUser && currentUser.companyId) {
      fetchAdminIntegrationsData();
    } else {
      setIsLoading(false);
      setError('User information is not available. Please ensure you are logged in with the correct permissions.');
    }
  }, [currentUser]);

  const fetchAdminIntegrationsData = async () => {
    try {
      setIsLoading(true);
      setError(null);
      if (currentUser && (currentUser.role === 'Admin' || currentUser.role === 'Super Admin')) {
        const adminIntegrationsData = await fetchAdminIntegrations(currentUser.companyId);
        setAdminIntegrations(adminIntegrationsData);
      } else {
        setError('You do not have the required permissions to view this page.');
      }
    } catch (err) {
      setError('Failed to fetch integrations. Please try again later.');
      console.error('Error fetching admin integrations:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddAdminIntegration = async () => {
    if (!currentUser || !currentUser.companyId) {
      setError('User information is not available. Please try logging in again.');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      const addedIntegration = await addAdminIntegration({
        ...newAdminIntegration,
        companyId: currentUser.companyId
      });
      if (addedIntegration.type === 'Shippo') {
        const webhookUrl = `https://settersuite.com/api/webhooks/shippo/${addedIntegration.id}/${currentUser.companyId}`;
        await updateAdminIntegration(currentUser.companyId, addedIntegration.id, { webhook: webhookUrl });
      }
      await fetchAdminIntegrationsData();
      setNewAdminIntegration({
        name: '',
        type: '',
        apiKey: '',
        shippoEventType: '',
        shippoEnvironment: 'test',
      });
    } catch (err) {
      setError('Failed to add integration. Please try again.');
      console.error('Error adding admin integration:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateAdminIntegration = async (integrationId, updatedData) => {
    await updateAdminIntegration(currentUser.companyId, integrationId, updatedData);
    fetchAdminIntegrationsData();
  };

  const handleDeleteAdminIntegration = async (integrationId) => {
    await deleteAdminIntegration(currentUser.companyId, integrationId);
    fetchAdminIntegrationsData();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdminIntegration((prev) => ({ ...prev, [name]: value }));
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>Admin Integrations</Typography>
      
      {/* Form for adding new admin integrations */}
      <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: '20px', mb: 4 }}>
        <TextField
          name="name"
          label="Integration Name"
          value={newAdminIntegration.name}
          onChange={handleInputChange}
        />
        <Select
          name="type"
          value={newAdminIntegration.type}
          onChange={handleInputChange}
          displayEmpty
        >
          <MenuItem value="" disabled>Select Integration Type</MenuItem>
          <MenuItem value="Shippo">Shippo</MenuItem>
          {/* Add more admin integration types as needed */}
        </Select>
        <TextField
          name="apiKey"
          label="API Key"
          value={newAdminIntegration.apiKey}
          onChange={handleInputChange}
        />
        <TextField
          name="webhook"
          label="Webhook URL"
          value={newAdminIntegration.webhook}
          onChange={handleInputChange}
        />
        {newAdminIntegration.type === 'Shippo' && (
          <>
            <Select
              name="shippoEventType"
              value={newAdminIntegration.shippoEventType}
              onChange={handleInputChange}
              displayEmpty
            >
              <MenuItem value="" disabled>Select Shippo Event Type</MenuItem>
              <MenuItem value="All Events">All Events</MenuItem>
              <MenuItem value="Track Updated">Track Updated</MenuItem>
              <MenuItem value="Batch Purchased">Batch Purchased</MenuItem>
              <MenuItem value="Batch Created">Batch Created</MenuItem>
              <MenuItem value="Transaction Created">Transaction Created</MenuItem>
              <MenuItem value="Transaction Updated">Transaction Updated</MenuItem>
            </Select>
            <Select
              name="shippoEnvironment"
              value={newAdminIntegration.shippoEnvironment}
              onChange={handleInputChange}
            >
              <MenuItem value="test">Test</MenuItem>
              <MenuItem value="prod">Production</MenuItem>
            </Select>
          </>
        )}
        <Button variant="contained" color="primary" onClick={handleAddAdminIntegration}>
          Add Admin Integration
        </Button>
      </Box>

      {/* Table of existing admin integrations */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>API Key</TableCell>
              <TableCell>Webhook</TableCell>
              <TableCell>Shippo Event Type</TableCell>
              <TableCell>Shippo Environment</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {adminIntegrations.map((integration) => (
    <TableRow key={integration.id}>
      <TableCell>{integration.name}</TableCell>
      <TableCell>{integration.type}</TableCell>
      <TableCell>{integration.apiKey}</TableCell>
      <TableCell>
        {integration.webhook}
        {integration.webhook && (
          <Button
            size="small"
            onClick={() => navigator.clipboard.writeText(integration.webhook)}
          >
            Copy
          </Button>
        )}
      </TableCell>
      <TableCell>{integration.shippoEventType}</TableCell>
      <TableCell>{integration.shippoEnvironment}</TableCell>
      <TableCell>
        <Button onClick={() => handleUpdateAdminIntegration(integration.id, { ...integration })}>
          Edit
        </Button>
        <Button onClick={() => handleDeleteAdminIntegration(integration.id)}>
          Delete
        </Button>
      </TableCell>
    </TableRow>
  ))}
</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AdminIntegrations;