import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, Tabs, Tab } from '@mui/material';
import { getCurrentUser } from '../services/IntegrationService';
import Sidebar from './Sidebar';
import GeneralIntegrations from './GeneralIntegrations';
import AdminIntegrations from './AdminIntegrations';

const IntegrationsPage = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getCurrentUser();
        setCurrentUser(user);
      } catch (error) {
        console.error('Error fetching current user:', error);
        // Handle the error appropriately (e.g., redirect to login page)
      }
    };
    fetchUser();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <Box sx={{ flex: 1, padding: '20px' }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', marginTop: '20px' }}>
          Integrations
        </Typography>
        <Divider sx={{ marginBottom: '20px' }} />
        
        <Tabs value={activeTab} onChange={handleTabChange} centered>
          <Tab label="General Integrations" />
          {currentUser && (currentUser.role === 'Admin' || currentUser.role === 'Super Admin') && (
            <Tab label="Admin Integrations" />
          )}
        </Tabs>

        {activeTab === 0 && <GeneralIntegrations currentUser={currentUser} />}
        {activeTab === 1 && currentUser && (currentUser.role === 'Admin' || currentUser.role === 'Super Admin') && (
          <AdminIntegrations currentUser={currentUser} />
        )}
      </Box>
    </Box>
  );
};

export default IntegrationsPage;