import { useState, useEffect } from 'react';
import { Box, TextField, Button, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { fetchCompanies } from './Firebase';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';

// Assuming firebase has already been initialized elsewhere

const CreateUserForm = ({ onUserCreated, currentUser }) => {
  const [formData, setFormData] = useState({
    username: '',
    name: '',
    email: '',
    phone: '',
    role: '',
    companyUmbrella: '',
    password: 'temporaryPassword' // Include a default temporary password
  });

  const [companies, setCompanies] = useState([]);
  const [initialUser, setInitialUser] = useState(null);

  useEffect(() => {
    const loadCompanies = async () => {
      const companiesData = await fetchCompanies();
      setCompanies(companiesData);
    };

    loadCompanies();
  }, []);

  useEffect(() => {
    const auth = getAuth();
    setInitialUser(auth.currentUser);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'companyUmbrella') {
      setFormData({ ...formData, companyUmbrella: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      const firestore = getFirestore();
      const newUser = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const userId = newUser.user.uid;

      // Add the user to Firestore
      const userDocRef = doc(firestore, 'users', userId);
      await setDoc(userDocRef, {
        username: formData.username,
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        role: formData.role,
        companyUmbrella: formData.companyUmbrella
      });

      // Reauthenticate the initial user
      if (initialUser) {
        await signInWithEmailAndPassword(auth, initialUser.email, 'temporaryPassword');
      }

      setFormData({
        username: '',
        name: '',
        email: '',
        phone: '',
        role: '',
        companyUmbrella: '',
        password: 'temporaryPassword' // Reset the password field
      });

      onUserCreated();
      alert('User created successfully!');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        alert('The email address is already in use. Please try signing in instead.');
      } else {
        console.error('Error creating user:', error);
        alert('Error creating user. Please try again.');
      }
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Create User
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Username"
          name="username"
          value={formData.username}
          onChange={handleInputChange}
          //required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Phone Number"
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          required
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Role</InputLabel>
          <Select
            name="role"
            value={formData.role}
            onChange={handleInputChange}
            required
          >
            {currentUser.role === 'Super Admin' && (
              <MenuItem value="Super Admin">Super Admin</MenuItem>
            )}
            <MenuItem value="Owner">Owner</MenuItem>
            <MenuItem value="User">User</MenuItem>
          </Select>
        </FormControl>
        {currentUser.role === 'Super Admin' && (
          <FormControl fullWidth margin="normal">
            <InputLabel>Company Umbrella</InputLabel>
            <Select
              name="companyUmbrella"
              value={formData.companyUmbrella}
              onChange={handleInputChange}
              required
            >
              {companies.map(company => (
                <MenuItem key={company.id} value={company.name}>{company.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Button type="submit" variant="contained" color="primary" sx={{ marginTop: '20px' }}>
          Create
        </Button>
      </form>
    </Box>
  );
};

export default CreateUserForm;
