import React, { createContext, useState, useEffect, useContext } from 'react';
import { ref, onValue, set, get } from 'firebase/database';
import { database } from './Firebase';
import { UserContext } from './UserContext';

export const CreditsContext = createContext();

export const CreditsProvider = ({ children }) => {
  const [credits, setCredits] = useState(0);
  const [loading, setLoading] = useState(true);
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const userContext = useContext(UserContext);

  useEffect(() => {
    const fetchCredits = async () => {
      if (userContext && userContext.user && userContext.user.companyUmbrella) {
        const companiesRef = ref(database, 'companies');
        
        onValue(companiesRef, async (snapshot) => {
          const companies = snapshot.val();
          for (const [key, value] of Object.entries(companies)) {
            if (value.name === userContext.user.companyUmbrella) {
              setCompanyId(key);
              setCompanyName(value.name);
              setCredits(value.credits || 0);
              break;
            }
          }
          setLoading(false);
        }, (error) => {
          console.error('Error fetching companies:', error);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    };

    fetchCredits();
  }, [userContext]);

  const updateCredits = async (amount) => {
    if (companyId) {
      const newCredits = credits + amount;
      const creditsRef = ref(database, `companies/${companyId}/credits`);
      await set(creditsRef, newCredits);
      setCredits(newCredits);
    }
  };

  const refreshCredits = async () => {
    if (companyId) {
      const creditsRef = ref(database, `companies/${companyId}/credits`);
      const snapshot = await get(creditsRef);
      const creditsValue = snapshot.val();
      setCredits(creditsValue || 0);
    }
  };

  return (
    <CreditsContext.Provider value={{ credits, updateCredits, refreshCredits, loading, companyName }}>
      {children}
    </CreditsContext.Provider>
  );
};