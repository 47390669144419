import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, FormControl, InputLabel, Select } from '@mui/material';

const LeadForm = ({ open, onClose, onAddLead, currentUser }) => {
  useEffect(() => {
    console.log('LeadForm mounted');
    return () => {
      console.log('LeadForm unmounted');
    };
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    leadSource: currentUser && (currentUser.role === 'Owner' || currentUser.role === 'User') ? 'Direct' : '',
    leadCycle: 'New Lead'
  });

  const states = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
    'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts',
    'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
    'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia',
    'Wisconsin', 'Wyoming'
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const geocodeAddress = async (street, city, state, zip) => {
    const address = `${street}, ${city}, ${state}, ${zip}`;
    console.log('Geocoding address:', address); // Log the address being geocoded
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`);
      const data = await response.json();
  
      if (data.length > 0) {
        const location = data[0];
        return {
          latitude: parseFloat(location.lat),
          longitude: parseFloat(location.lon)
        };
      } else {
        throw new Error('Geocoding failed: No results found');
      }
    } catch (error) {
      console.error('Geocoding error:', error);
      throw new Error('Geocoding failed: Unable to fetch data');
    }
  };
  
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { latitude, longitude } = await geocodeAddress(formData.street, formData.city, formData.state, formData.zip);
      const newLead = {
        ...formData,
        latitude,
        longitude
      };
      onAddLead(newLead);
      setFormData({
        name: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        leadSource: currentUser && (currentUser.role === 'Owner' || currentUser.role === 'User') ? 'Direct' : '',
        leadCycle: 'New Lead'
      });
      onClose();
    } catch (error) {
      console.error('Error adding lead:', error.message);
      // Optionally, you can show a snackbar or modal to inform the user of the geocoding error
    }
  };
  

  if (!currentUser) {
    return null; // or any loading indicator/message if needed
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Lead</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField fullWidth margin="normal" label="Name" name="name" value={formData.name} onChange={handleInputChange} required />
          <TextField fullWidth margin="normal" label="Email" type="email" name="email" value={formData.email} onChange={handleInputChange} required />
          <TextField fullWidth margin="normal" label="Phone" type="tel" name="phone" value={formData.phone} onChange={handleInputChange} required />
          <TextField fullWidth margin="normal" label="Street" name="street" value={formData.street} onChange={handleInputChange} required />
          <TextField fullWidth margin="normal" label="City" name="city" value={formData.city} onChange={handleInputChange} required />
          <FormControl fullWidth margin="normal">
            <InputLabel id="state-label">State</InputLabel>
            <Select labelId="state-label" id="state" name="state" value={formData.state} onChange={handleInputChange} required>
              {states.map((state) => (
                <MenuItem key={state} value={state}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField fullWidth margin="normal" label="Zip Code" name="zip" value={formData.zip} onChange={handleInputChange} required />
          {currentUser.role === 'Super Admin' && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="lead-source-label">Lead Source</InputLabel>
              <Select labelId="lead-source-label" id="leadSource" name="leadSource" value={formData.leadSource} onChange={handleInputChange} required>
                <MenuItem value="Direct">Direct</MenuItem>
                <MenuItem value="Ads">Ads</MenuItem>
                <MenuItem value="Referral">Referral</MenuItem>
                <MenuItem value="Social Media">Social Media</MenuItem>
                <MenuItem value="Events">Events</MenuItem>
              </Select>
            </FormControl>
          )}
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">Add Lead</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default LeadForm;
