import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ref, update, onValue } from 'firebase/database';
import { storage } from './Firebase';
import { getDownloadURL, ref as storageRef, uploadBytesResumable, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { database } from './Firebase';

const EditCompanyCustomerForm = ({ company }) => {
  const [formFields, setFormFields] = useState(company.formFields || []);
  const [newFieldType, setNewFieldType] = useState('');
  const [newFieldOptions, setNewFieldOptions] = useState([]);
  const [newFieldQuestion, setNewFieldQuestion] = useState('');
  const [logoUrl, setLogoUrl] = useState(company.logoUrl || '');
  const [companyName, setCompanyName] = useState(company.formName || '');
  const [slogan, setSlogan] = useState(company.slogan || '');
  const [description, setDescription] = useState(company.description || '');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [editingField, setEditingField] = useState(null);

  useEffect(() => {
    const companyRef = ref(database, `companies/${company.id}`);
    onValue(companyRef, (snapshot) => {
      const companyData = snapshot.val();
      if (companyData) {
        setFormFields(companyData.formFields || []);
        setLogoUrl(companyData.logoUrl || '');
        setCompanyName(companyData.formName || '');
        setSlogan(companyData.slogan || '');
        setDescription(companyData.description || '');
      }
    });
  }, [company.id]);

  const handleAddField = () => {
    if (editingField) {
      const updatedFields = formFields.map((field) =>
        field.id === editingField.id
          ? { ...field, type: newFieldType, options: newFieldOptions, question: newFieldQuestion }
          : field
      );
      setFormFields(updatedFields);
      setEditingField(null);
    } else {
      setFormFields([
        ...formFields,
        { id: uuidv4(), type: newFieldType, options: newFieldOptions, question: newFieldQuestion }
      ]);
    }
    setNewFieldType('');
    setNewFieldOptions([]);
    setNewFieldQuestion('');
  };

  const handleSaveForm = async () => {
    const companyRef = ref(database, `companies/${company.id}`);
    await update(companyRef, {
      formFields,
      formName: companyName,
      logoUrl,
      slogan,
      description
    });
  };

  const handleLogoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (logoUrl) {
      // Delete the existing logo
      const logoRef = storageRef(storage, logoUrl);
      await deleteObject(logoRef);
    }

    const storageReference = storageRef(storage, `logos/${company.id}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageReference, file);

    uploadTask.on(
      'state_changed',
      snapshot => {},
      error => {
        console.error('Upload failed:', error);
      },
      async () => {
        const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
        setLogoUrl(downloadUrl);

        const companyRef = ref(database, `companies/${company.id}`);
        await update(companyRef, { logoUrl: downloadUrl });
      }
    );
  };

  const handleDeleteLogo = async () => {
    if (logoUrl) {
      const logoRef = storageRef(storage, logoUrl);
      await deleteObject(logoRef);
      setLogoUrl('');

      const companyRef = ref(database, `companies/${company.id}`);
      await update(companyRef, { logoUrl: '' });
    }
  };

  const handleEditField = (field) => {
    setEditingField(field);
    setNewFieldType(field.type);
    setNewFieldOptions(field.options || []);
    setNewFieldQuestion(field.question);
  };

  const moveField = (index, direction) => {
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= formFields.length) return;
    const updatedFields = [...formFields];
    const [movedField] = updatedFields.splice(index, 1);
    updatedFields.splice(newIndex, 0, movedField);
    setFormFields(updatedFields);
  };

  const renderFormPreview = () => (
    <Box p={3}>
      <Typography variant="h4" align="center">{companyName}</Typography>
      {logoUrl && <img src={logoUrl} alt="Company Logo" style={{ maxWidth: '200px', maxHeight: '200px', display: 'block', margin: '0 auto 20px auto' }} />}
      <Typography variant="h6" align="center">{slogan}</Typography>
      <Typography variant="body1" align="center">{description}</Typography>
      {formFields.map((field) => (
        <Box key={field.id} mt={2}>
          <Typography variant="subtitle1">{field.question}</Typography>
          {field.type === 'text' ? (
            <TextField fullWidth variant="outlined" />
          ) : (
            <FormControl fullWidth>
              <Select defaultValue="">
                {field.options.map((option, index) => (
                  <MenuItem key={index} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      ))}
      <Button variant="contained" color="primary" sx={{ marginTop: '20px' }}>
        Submit
      </Button>
    </Box>
  );

  return (
    <Box>
      <Typography variant="h6">Edit Form</Typography>
      <Box mt={2}>
        <TextField
          label="Company Form Name"
          variant="outlined"
          fullWidth
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          sx={{ marginBottom: '20px' }}
        />
        <TextField
          label="Slogan"
          variant="outlined"
          fullWidth
          value={slogan}
          onChange={(e) => setSlogan(e.target.value)}
          sx={{ marginBottom: '20px' }}
        />
        <TextField
          label="Description"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ marginBottom: '20px' }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Button variant="contained" component="label">
            Upload Logo
            <input type="file" hidden onChange={handleLogoUpload} />
          </Button>
          {logoUrl && (
            <>
              <IconButton onClick={handleDeleteLogo}>
                <DeleteIcon />
              </IconButton>
              <img src={logoUrl} alt="Company Logo" style={{ maxWidth: '200px', maxHeight: '200px', display: 'block', marginLeft: '20px' }} />
            </>
          )}
        </Box>
        <TextField
          label="New Field Question"
          variant="outlined"
          fullWidth
          value={newFieldQuestion}
          onChange={(e) => setNewFieldQuestion(e.target.value)}
          sx={{ marginBottom: '20px' }}
        />
        <FormControl fullWidth sx={{ marginBottom: '20px' }}>
          <InputLabel id="new-field-type-label">New Field Type</InputLabel>
          <Select
            labelId="new-field-type-label"
            value={newFieldType}
            onChange={(e) => setNewFieldType(e.target.value)}
          >
            <MenuItem value="text">Text Input</MenuItem>
            <MenuItem value="select">Select Dropdown</MenuItem>
          </Select>
        </FormControl>
        {newFieldType === 'select' && (
          <Box>
            {newFieldOptions.map((option, index) => (
              <TextField
                key={index}
                label={`Option ${index + 1}`}
                variant="outlined"
                fullWidth
                value={option}
                onChange={(e) => {
                  const updatedOptions = [...newFieldOptions];
                  updatedOptions[index] = e.target.value;
                  setNewFieldOptions(updatedOptions);
                }}
                sx={{ marginBottom: '10px' }}
              />
            ))}
            <Button
              variant="contained"
              onClick={() => setNewFieldOptions([...newFieldOptions, ''])}
            >
              Add Option
            </Button>
          </Box>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddField}
          sx={{ marginTop: '20px' }}
        >
          {editingField ? 'Update Field' : 'Add Field'}
        </Button>
        <Box sx={{ marginTop: '20px' }}>
          {formFields.map((field, index) => (
            <ListItem
              key={field.id}
              sx={{
                padding: '10px',
                marginBottom: '10px',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <ListItemText
                  primary={`${field.type.charAt(0).toUpperCase() + field.type.slice(1)} Field: ${field.question}`}
                  secondary={
                    field.type === 'select'
                      ? `Options: ${field.options.join(', ')}`
                      : 'Text Input'
                  }
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => moveField(index, -1)}
                  disabled={index === 0}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => moveField(index, 1)}
                  disabled={index === formFields.length - 1}
                >
                  <ArrowDownwardIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => handleEditField(field)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    const updatedFields = formFields.filter((_, i) => i !== index);
                    setFormFields(updatedFields);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </ListItem>
          ))}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveForm}
          sx={{ marginTop: '20px' }}
        >
          Save Form
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setPreviewOpen(true)}
          sx={{ marginTop: '20px' }}
        >
          Preview Form
        </Button>
        <Typography variant="body1" sx={{ marginTop: '20px' }}>
          Form URL: 
          <a href={`http://localhost:3000/${company.id}/form`} target="_blank" rel="noopener noreferrer">
            {`http://localhost:3000/${company.id}/form`}
          </a>
        </Typography>
        <Typography variant="body1">
          Form URL: 
          <a href={`http://settersuite.com/${company.id}/form`} target="_blank" rel="noopener noreferrer">
            {`http://settersuite.com/${company.id}/form`}
          </a>
        </Typography>
        <Modal
          open={previewOpen}
          onClose={() => setPreviewOpen(false)}
        >
          <Paper style={{ margin: 'auto', marginTop: '10%', padding: '20px', width: '80%', maxHeight: '80%', overflowY: 'auto' }}>
            {renderFormPreview()}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setPreviewOpen(false)}
              sx={{ marginTop: '20px' }}
            >
              Close Preview
            </Button>
          </Paper>
        </Modal>
      </Box>
    </Box>
  );
};

export default EditCompanyCustomerForm;
