import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Paper, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { auth, db } from './Firebase';
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Logo from '../assets/SetterSuiteLogoNew.png';
import Background from '../assets/background27.jpg';
import axios from 'axios'; // Import axios for making HTTP requests
import { UserContext } from './UserContext';

const LoginContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: `url(${Background})`,
  position: 'relative',
}));

const LogoImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
  maxWidth: '200px',
  height: 'auto',
}));

const LoginCard = styled(Paper)(({ theme }) => ({
  maxWidth: 400,
  padding: "4rem 1.5rem 1rem 1.5rem",
  borderRadius: "15px",
  boxShadow: theme.shadows[5],
  textAlign: 'center',
  backdropFilter: 'blur(5px)',
  color: 'black',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') setEmail(value);
    else if (name === 'password') setPassword(value);
  };

  const { clearUser } = useContext(UserContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      

      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        
        const apiUrl = process.env.REACT_APP_API_URL.replace(/\/$/, '');

        if (['Super Admin', 'Owner', 'User'].includes(userData.role)) {
          // Log the login event by sending a request to your server
          const response = await axios.post(`${apiUrl}/login`, { userId: user.uid, email: user.email });
          
          navigate('/dashboard');
        } else {
          alert('You do not have the necessary permissions.');
        }
      } else {
        console.log('User document does not exist for UID:', user.uid);
        alert('No user data found.');
      }
    } catch (error) {
      console.error('Error logging in:', error.response ? error.response.data : error.message);
      alert('Login failed. Please check the console for more details.');
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    navigate('/reset-password');
  };

  return (
    <LoginContainer>
      <LogoImage src={Logo} alt="Logo" />
      <LoginCard>
        <Typography variant="h4" gutterBottom>Login</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={email}
            onChange={handleChange}
            required
            margin="normal"
            InputProps={{
              startAdornment: (
                <IconWrapper>
                  <EmailIcon />
                </IconWrapper>
              ),
            }}
          />
          <TextField
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={password}
            onChange={handleChange}
            required
            margin="normal"
            InputProps={{
              startAdornment: (
                <IconWrapper>
                  <LockIcon />
                </IconWrapper>
              ),
            }}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 2, position: 'relative' }}
            disabled={loading}
            color="primary"
          >
            {loading ? <CircularProgress size={24} /> : 'Login'}
          </Button>
        </form>
        <Button
          variant="text"
          color="secondary"
          sx={{ mt: 2 }}
          onClick={handleForgotPassword}
        >
          Forgot Password?
        </Button>
      </LoginCard>
    </LoginContainer>
  );
};

export default Login;