const API_BASE_URL = 'https://calendar-proxy.vercel.app'; // Proxy server URL

export const createEvent = async (inputData) => {
  const { start, end, ...otherProps } = inputData;

  // Basic validation checks
  if (isNaN(Date.parse(start.dateTime)) || isNaN(Date.parse(end.dateTime))) {
    throw new Error('Invalid start or end time');
  }

  // Use the dateTime values directly as they should already be in ISO 8601 format
  const formattedEvent = {
    ...otherProps,
    start,
    end,
  };

  // Log the event details before sending
  console.log('Formatted event:', formattedEvent);

  try {
    const response = await fetch(`${API_BASE_URL}/createEvent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formattedEvent),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }

    return response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};

export const listUpcomingEvents = async (timeMin, timeMax) => {
  const response = await fetch(`${API_BASE_URL}/listUpcomingEvents`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ timeMin, timeMax }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to fetch events');
  }

  return response.json();
};
