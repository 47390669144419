import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Divider, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
} from '@mui/material';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { ref, onValue, remove, set } from 'firebase/database';
import Sidebar from './Sidebar';
import { database } from './Firebase';

const ArchivedLeads = () => {
  const [archivedLeads, setArchivedLeads] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for initial data fetch

  useEffect(() => {
    const leadsRef = ref(database, 'archivedLeads');
    const unsubscribe = onValue(leadsRef, (snapshot) => {
      const data = snapshot.val();
      const archivedLeadsArray = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
      setArchivedLeads(archivedLeadsArray);
      setLoading(false); // Update loading state once data is fetched
    }, {
      onlyOnce: true // Added to prevent the effect from being triggered on every render
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const restoreLead = async (lead) => {
    try {
        const archivedLeadRef = ref(database, `archivedLeads/${lead.id}`);
        const leadRef = ref(database, `leads/${lead.id}`);

        // Atomically remove from archivedLeads and add to leads
        await set(leadRef, lead); // Move lead back to leads
        await remove(archivedLeadRef); // Remove lead from archivedLeads

        console.log(`Lead restored successfully: ${lead.id}`);
    } catch (error) {
        console.error('Error restoring lead:', error);
    }
};



  if (loading) {
    return <Typography variant="body1">Loading archived leads...</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <Box sx={{ flex: 1, padding: '20px' }}>
        <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '20px' }}>
          Archived Leads
        </Typography>
        <Divider sx={{ marginBottom: '20px' }} />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lead ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Restore</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {archivedLeads.map((lead) => (
                <TableRow key={lead.id}>
                  <TableCell>{lead.id}</TableCell>
                  <TableCell>{lead.name}</TableCell>
                  <TableCell>{lead.email}</TableCell>
                  <TableCell>
                    <Button onClick={() => restoreLead(lead)} variant="contained"><UnarchiveIcon />Restore</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ArchivedLeads;
