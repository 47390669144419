import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Snackbar,
  Divider
} from '@mui/material';
import { fetchCompanies, updateCompanyCredits } from './Firebase';
import Sidebar from './Sidebar';

const CompanyCredits = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [currentCredits, setCurrentCredits] = useState(0);
  const [creditsToUpdate, setCreditsToUpdate] = useState(0);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companiesData = await fetchCompanies();
        setCompanies(companiesData);

        

        if (companiesData.length > 0) {
          setSelectedCompany(companiesData[0]);
          setCurrentCredits(companiesData[0].credits || 0);
        }
      } catch (error) {
        setError(error.message);
        console.error('Error fetching companies:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      setCurrentCredits(selectedCompany.credits || 0);
    }
  }, [selectedCompany]);

  const handleCompanyChange = (event) => {
    const newCompanyId = event.target.value;
    const selected = companies.find(company => company.companyId === newCompanyId);
    
    setSelectedCompany(selected);
  };

  const handleCreditsChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setCreditsToUpdate(isNaN(value) ? 0 : value);
  };

  const handleUpdateCredits = async () => {
    
    if (selectedCompany && selectedCompany.name) {
      console.log('Updating credits for company ID:', selectedCompany.name); 
      try {
        await updateCompanyCredits(selectedCompany.name, creditsToUpdate); // Use companyId
        console.log('Credits updated successfully for', selectedCompany.name);
        setSnackbarOpen(true);
        setCurrentCredits(prev => prev + creditsToUpdate);
      } catch (error) {
        console.error('Error updating credits:', error);
        setError('Failed to update credits. Please try again.');
      }
    } else {
      console.error('No company selected or company ID is missing.');
      setError('No company selected or company ID is missing.');
    }
  };  

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        <Typography variant="h5" gutterBottom>
          Company Credits
        </Typography>
        <Divider sx={{ marginY: '20px' }} />
        <FormControl fullWidth sx={{ marginBottom: '20px' }}>
          <InputLabel id="company-select-label">Select Company</InputLabel>
          <Select
            labelId="company-select-label"
            value={selectedCompany ? selectedCompany.companyId : ''}
            onChange={handleCompanyChange}
          >
            {companies.map((company) => (
              <MenuItem key={company.companyId} value={company.companyId}>
                {company.name || 'Unnamed Company'}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedCompany && (
          <Typography variant="body1" gutterBottom>
            Current Credits: {currentCredits}
          </Typography>
        )}
        <FormControl fullWidth sx={{ marginBottom: '20px' }}>
          <TextField
            label="Credits"
            value={creditsToUpdate}
            onChange={handleCreditsChange}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateCredits}
          disabled={!selectedCompany || creditsToUpdate === 0}
        >
          Update Credits
        </Button>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          message="Credits updated successfully!"
        />
        {error && <Typography color="error">{error}</Typography>}
      </Box>
    </Box>
  );
};

export default CompanyCredits;
