import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Snackbar,
  Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { generateApiKey, fetchApiKeys, addApiKey } from '../services/IntegrationService';
import { getCurrentUser, fetchCompanies } from './Firebase';
import Sidebar from './Sidebar'; // Ensure the correct path

const APIIntegrations = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [apiKeys, setApiKeys] = useState([]);
  const [apiKeyName, setApiKeyName] = useState('');
  const [newApiKey, setNewApiKey] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const user = await getCurrentUser();
        const companiesData = await fetchCompanies();
        setCompanies(companiesData);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      const fetchCompanyData = async () => {
        try {
          const apiKeysData = await fetchApiKeys(selectedCompany);
          setApiKeys(apiKeysData);
        } catch (error) {
          setError(error.message);
          console.error('Error fetching company data:', error);
        }
      };
      fetchCompanyData();
    }
  }, [selectedCompany]);

  const handleGenerateApiKey = async () => {
    const apiKey = generateApiKey();
    const apiKeyData = {
      name: apiKeyName,
      apiKey,
    };

    try {
      await addApiKey(selectedCompany, apiKeyData);
      setNewApiKey(apiKey);
      setDialogOpen(true);
      const apiKeysData = await fetchApiKeys(selectedCompany);
      setApiKeys(apiKeysData);
      setApiKeyName('');
    } catch (error) {
      setError(error.message);
      console.error('Error generating API key:', error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCompanyChange = (event) => {
    const companyId = event.target.value;
    setSelectedCompany(companyId);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        <Typography variant="h5" gutterBottom>
          Manage API Keys
        </Typography>
        <Divider sx={{ marginY: '20px' }} />
        <FormControl fullWidth sx={{ marginBottom: '20px' }}>
          <InputLabel id="company-select-label">Select Company</InputLabel>
          <Select
            labelId="company-select-label"
            value={selectedCompany}
            onChange={handleCompanyChange}
          >
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.name}>{company.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <List sx={{ marginBottom: '20px' }}>
          {Array.isArray(apiKeys) && apiKeys.map((apiKey, index) => (
            <ListItem key={index}>
              <ListItemText primary={apiKey.name} secondary={apiKey.apiKey} />
            </ListItem>
          ))}
        </List>
        <FormControl fullWidth sx={{ marginBottom: '20px' }}>
          <TextField
            label="API Key Name"
            value={apiKeyName}
            onChange={(e) => setApiKeyName(e.target.value)}
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleGenerateApiKey}
          disabled={!selectedCompany || !apiKeyName}
        >
          Generate API Key
        </Button>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>API Key Generated</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This is your new API key. Make sure to copy it as you won't be able to see it again.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="New API Key"
              fullWidth
              value={newApiKey}
              InputProps={{
                readOnly: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          message="API key generated successfully!"
        />
        {error && <Typography color="error">{error}</Typography>}
      </Box>
    </Box>
  );
};

export default APIIntegrations;
