import React from 'react';
import { Box, Typography } from '@mui/material';

const ThankYouPage = () => {
  return (
    <Box p={3} textAlign="center">
      <Typography variant="h4">Thank You!</Typography>
      <Typography variant="body1">We have received your form submission and will get back to you shortly.</Typography>
    </Box>
  );
};

export default ThankYouPage;
