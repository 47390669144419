import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Container, Typography, Box, Button } from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import backgroundImg from '../assets/pexels-vlada-karpovich-9969234.jpg'; // Example background image
import moment from 'moment';

const Root = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  minHeight: '100vh',
  backgroundImage: `url(${backgroundImg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: theme.palette.text.primary,
}));

const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: '#fff',
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}));

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  textTransform: 'none',
  padding: theme.spacing(1.5),
  margin: theme.spacing(0.5),
  boxShadow: theme.shadows[1],
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    background: {
      default: '#f4f6f8',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 500,
    },
  },
});

const DemoConfirmed = () => {
  const location = useLocation();
  const { eventDetails } = location.state || {};

  return (
    <ThemeProvider theme={theme}>
      <Root>
        <Header>
          <Typography variant="h4">Demo Confirmed</Typography>
        </Header>
        <Container maxWidth="md">
          <Box mb={4} textAlign="center">
            <Typography variant="h6" gutterBottom>
              Thank you! Your demo has been scheduled successfully.
            </Typography>
            {eventDetails && (
              <Box>
                <Typography variant="body1">
                  <strong>Event:</strong> {eventDetails.summary}
                </Typography>
                <Typography variant="body1">
                  <strong>Start:</strong> {moment(eventDetails.start.dateTime).format('LLL')}
                </Typography>
                <Typography variant="body1">
                  <strong>End:</strong> {moment(eventDetails.end.dateTime).format('LLL')}
                </Typography>
                <Typography variant="body1">
                  <strong>Location:</strong> {eventDetails.hangoutLink}
                </Typography>
              </Box>
            )}
            <CustomButton
              variant="contained"
              color="primary"
              component={Link}
              to="/"
              sx={{ mt: 2 }}
            >
              Back to Home
            </CustomButton>
          </Box>
        </Container>
      </Root>
    </ThemeProvider>
  );
};

export default DemoConfirmed;
