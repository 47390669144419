// Billing.js
import React, { useContext, useState, useEffect } from 'react';
import { CreditsContext } from './CreditsContext';
import {
  Box,
  Typography,
  Divider,
  Button,
  TextField,
} from '@mui/material';
import Sidebar from '../components/Sidebar';

const Billing = () => {
  const { updateCredits } = useContext(CreditsContext);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePurchase = () => {
    updateCredits(amount);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <Box sx={{ flex: 1, padding: '20px' }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '20px' }}
        >
          Billing
        </Typography>
        <Divider sx={{ marginBottom: '20px' }} />

        <TextField
          label="Credits"
          type="number"
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
        />
        <Button variant="contained" color="primary" onClick={handlePurchase}>
          Purchase Credits
        </Button>

        {/* Placeholder for the Stripe Pricing Table */}
        <Box id="stripe-pricing-table-container" sx={{ marginTop: '20px' }}></Box>

        {/* Stripe Pricing Table */}
          <stripe-pricing-table pricing-table-id="prctbl_1Ptb5RALlZX9Dip3ZQHAxpRc"
          publishable-key="pk_test_51PtadUALlZX9Dip38Ws4w0sARlJ8jWq13pK5b64vZPIb4YgIRvpYkJyawprncbICgSCjDT6RkuR1KvCLJBRTEB5400hc8mqOaa">
          </stripe-pricing-table>
      </Box>
    </Box>
  );
};

export default Billing;

