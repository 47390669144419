import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Divider, Modal, List, ListItem, ListItemText, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getAuth, updatePassword } from 'firebase/auth';
import { doc, getDoc, updateDoc, collection, getDocs, query as firestoreQuery, where, orderBy } from 'firebase/firestore'; // Renamed query import
import { getDatabase, ref, query as realtimeQuery, orderByChild, get } from 'firebase/database'; // Renamed query import
import { db } from './Firebase';
import Sidebar from './Sidebar';
import { createEvent } from './GoogleCalendarService';
import logEvent from './logEvent';

const Profile = () => {
  const theme = useTheme();
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const [userData, setUserData] = useState(null);
  const [editableFields, setEditableFields] = useState({
    name: false,
    phone: false,
    email: false,
  });
  const [error, setError] = useState(null);
  const [calendarConnected, setCalendarConnected] = useState(false);
  const [calendarEmail, setCalendarEmail] = useState('');
  const [logs, setLogs] = useState([]);
  const [logsOpen, setLogsOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userDoc = doc(db, 'users', currentUser.uid);
          const userSnapshot = await getDoc(userDoc);
          if (userSnapshot.exists()) {
            setUserData(userSnapshot.data());
            if (userSnapshot.data().calendarEmail) {
              setCalendarConnected(true);
              setCalendarEmail(userSnapshot.data().calendarEmail);
            }
          } else {
            console.error('No such document!');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError('Error fetching user data. Please try again.');
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleFieldChange = (field, value) => {
    setUserData({ ...userData, [field]: value });
  };

  const toggleEditMode = (field) => {
    setEditableFields({ ...editableFields, [field]: !editableFields[field] });
  };

  const handleSaveChanges = async () => {
    try {
      const userDoc = doc(db, 'users', currentUser.uid);
      await updateDoc(userDoc, userData);
      setSnackbarOpen(true);
      await logEvent(currentUser.uid, 'update_profile', { fieldsUpdated: editableFields });
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Error updating profile. Please try again.');
    }
  };

  const handleChangePassword = () => {
    setDialogOpen(true);
  };

  const handlePasswordChange = async () => {
    if (newPassword) {
      try {
        await updatePassword(currentUser, newPassword);
        setSnackbarOpen(true);
        setDialogOpen(false);
        await logEvent(currentUser.uid, 'change_password');
      } catch (error) {
        console.error('Error changing password:', error);
        setError('Error changing password. Please try again.');
      }
    }
  };

  const connectCalendar = async () => {
    try {
      const testEvent = {
        summary: `Connection Test with ${userData.name}`,
        start: { dateTime: new Date().toISOString() },
        end: { dateTime: new Date(new Date().getTime() + 30 * 60 * 1000).toISOString() },
        attendees: [{ email: userData.email }],
      };

      console.log('Test event:', JSON.stringify(testEvent, null, 2));

      const result = await createEvent(testEvent);
      console.log('Create event result:', result);

      await createEvent(testEvent);
      setCalendarEmail(userData.email);
      setCalendarConnected(true);

      // Save the representative's calendar email to Firestore
      const userDoc = doc(db, 'users', currentUser.uid);
      await updateDoc(userDoc, { calendarEmail: userData.email });

      setSnackbarOpen(true);
      await logEvent(currentUser.uid, 'connect_calendar', { calendarEmail: userData.email });
    } catch (error) {
      console.error('Detailed error:', error);
      console.error('Error stack:', error.stack);
      setError(`Error connecting Google Calendar: ${error.message}`);
    }
  };

  const fetchFirestoreLogs = async () => {
    const logsRef = collection(db, 'logs');
    const logsQuery = firestoreQuery(
      logsRef,
      where('userId', '==', currentUser.uid),
      orderBy('timestamp', 'desc')
    );
    const firestoreSnapshot = await getDocs(logsQuery);
    const firestoreLogs = [];
    firestoreSnapshot.forEach((doc) => {
      const logData = doc.data();
      logData.timestamp = logData.timestamp.toMillis(); // Convert Firestore Timestamp to milliseconds
      firestoreLogs.push(logData);
    });
    return firestoreLogs;
  };
  
  const fetchLogs = async () => {
    try {
      // Fetch Firestore logs
      const firestoreLogs = await fetchFirestoreLogs();
  
      // Fetch Realtime Database logs
      const realtimeLogs = await fetchRealtimeLogs();
      realtimeLogs.forEach(log => {
        log.timestamp = formatTimestamp(log.timestamp); // Format the timestamp
      });
  
      // Merge and sort logs
      const allLogs = [...firestoreLogs, ...realtimeLogs];
      allLogs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)); // Ensure sorting by timestamp
  
      setLogs(allLogs);
      setLogsOpen(true);
    } catch (error) {
      console.error('Error fetching logs:', error);
      setError('Error fetching logs. Please try again.');
    }
  };    

  const fetchRealtimeLogs = async () => {
    const db = getDatabase();
    const logsRef = ref(db, 'userActivity');
    const logsQuery = realtimeQuery(logsRef, orderByChild('timestamp'));
  
    try {
      const snapshot = await get(logsQuery);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const logs = Object.keys(data).map(key => {
          const timestamp = data[key].timestamp;
          console.log('Raw timestamp:', timestamp); // Debug log
          return {
            ...data[key],
            id: key,
            timestamp // Keep as milliseconds
          };
        });
        return logs;
      }
      return [];
    } catch (error) {
      console.error('Error fetching Realtime Database logs:', error);
      return [];
    }
  };    

  const formatTimestamp = (timestamp) => {
    let date;
    
    // Check if timestamp is a number (Unix timestamp)
    if (typeof timestamp === 'number') {
      date = new Date(timestamp);
    }
    // Check if timestamp is a string (ISO 8601 format)
    else if (typeof timestamp === 'string') {
      date = new Date(timestamp);
    }
    
    // Return formatted date
    if (date instanceof Date && !isNaN(date)) {
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
        timeZoneName: 'short',
      });
    }
    return timestamp; // Fallback if date is invalid
  };
  
  const handleLogsClose = () => {
    setLogsOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <Box sx={{ flex: 1, padding: '20px', backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary  }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            marginTop: '20px',
            color: theme.palette.text.primary
          }}
        >
          Profile
        </Typography>
        <Divider sx={{ marginBottom: '20px', backgroundColor: theme.palette.divider }} />
        {error && <Typography color="error">{error}</Typography>}
        <Box sx={{ marginBottom: '20px' }}>
          <TextField
            fullWidth
            label="Name"
            value={userData.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            disabled={!editableFields.name}
            InputProps={{
              endAdornment: (
                <Button onClick={() => toggleEditMode('name')} sx={{ color: theme.palette.primary.main }}>
                  {editableFields.name ? 'Save' : 'Edit'}
                </Button>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: theme.palette.divider,
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main,
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.text.secondary,
              },
              '& .MuiInputBase-input': {
                color: theme.palette.text.primary,
              },
            }}
          />
        </Box>
        <Box sx={{ marginBottom: '20px' }}>
          <TextField
            fullWidth
            label="Phone"
            value={userData.phone}
            onChange={(e) => handleFieldChange('phone', e.target.value)}
            disabled={!editableFields.phone}
            InputProps={{
              endAdornment: (
                <Button onClick={() => toggleEditMode('phone')} sx={{ color: theme.palette.primary.main }}>
                  {editableFields.phone ? 'Save' : 'Edit'}
                </Button>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: theme.palette.divider,
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main,
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.text.secondary,
              },
              '& .MuiInputBase-input': {
                color: theme.palette.text.primary,
              },
            }}
          />
        </Box>
        <Box sx={{ marginBottom: '20px' }}>
          <TextField
            fullWidth
            label="Email"
            value={userData.email}
            onChange={(e) => handleFieldChange('email', e.target.value)}
            disabled={!editableFields.email}
            InputProps={{
              endAdornment: (
                <Button onClick={() => toggleEditMode('email')} sx={{ color: theme.palette.primary.main }}>
                  {editableFields.email ? 'Save' : 'Edit'}
                </Button>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: theme.palette.divider,
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main,
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.text.secondary,
              },
              '& .MuiInputBase-input': {
                color: theme.palette.text.primary,
              },
            }}
          />
        </Box>
        <Box sx={{ marginBottom: '20px' }}>
          <TextField
            fullWidth
            label="Username"
            value={userData.username}
            disabled
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: theme.palette.divider,
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.text.secondary,
              },
              '& .MuiInputBase-input': {
                color: theme.palette.text.primary,
              },
            }}
          />
        </Box>
        <Box sx={{ marginBottom: '20px' }}>
          <TextField
            fullWidth
            label="User Role"
            value={userData.role}
            disabled
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: theme.palette.divider,
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.text.secondary,
              },
              '& .MuiInputBase-input': {
                color: theme.palette.text.primary,
              },
            }}
          />
        </Box>
        <Box sx={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
          <Button variant="contained" color="secondary" onClick={handleChangePassword}>
            Change Password
          </Button>
        </Box>
        <Box sx={{ marginBottom: '20px' }}>
          {calendarConnected ? (
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>Connected to Google Calendar: {calendarEmail}</Typography>
          ) : (
            <Button variant="contained" color="tertiary" onClick={connectCalendar}>
              Connect Google Calendar
            </Button>
          )}
        </Box>
        <Box sx={{ marginBottom: '20px' }}>
          <Button variant="contained" color="primary" onClick={fetchLogs}>
            View Logs
          </Button>
        </Box>
        <Modal open={logsOpen} onClose={handleLogsClose}>
          <Box sx={{ backgroundColor: theme.palette.background.paper, padding: '20px', margin: '20px auto', width: '80%', maxHeight: '80vh', overflowY: 'scroll', borderRadiusOverflowY: 'scroll', borderRadius: '10px', color: theme.palette.text.primary }}>
            <Typography variant="h6" gutterBottom>
              User Logs
            </Typography>
            <List>
  {logs.map((log, index) => (
    <ListItem key={index} sx={{ marginBottom: '10px', border: `1px solid ${theme.palette.divider}`, borderRadius: '5px', padding: '10px' }}>
      <ListItemText
        primary={
          <React.Fragment>
            <Typography variant="subtitle1" component="span" sx={{ color: theme.palette.text.primary }}>
              Action: {log.action}
            </Typography>
          </React.Fragment>
        }
        secondary={
          <React.Fragment>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }} component="span">
              Timestamp: {log.timestamp}
            </Typography>
            <br />
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }} component="span">
              Details: {JSON.stringify(log.details)}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  ))}
</List>

          </Box>
        </Modal>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            Operation successful!
          </Alert>
        </Snackbar>
        <Dialog open={dialogOpen} onClose={handleDialogClose} PaperProps={{
            style: {
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            },
          }}>
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="New Password"
              type="password"
              fullWidth
              variant="standard"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              sx={{
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.secondary,
                },
                '& .MuiInput-underline:before': {
                  borderBottomColor: theme.palette.divider,
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottomColor: theme.palette.primary.main,
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.text.primary,
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} sx={{ color: theme.palette.text.primary }}>Cancel</Button>
            <Button onClick={handlePasswordChange} color="primary">
              Change Password
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Profile;

