import React, { useState, useEffect } from 'react';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import { Box, Grid, Paper, Typography, Divider, Button, Menu, MenuItem } from '@mui/material';
import { Chart, LinearScale, CategoryScale, BarElement, ArcElement, registerables } from 'chart.js';
import Sidebar from './Sidebar';
import { database, getCurrentUser } from './Firebase';
import { ref, get } from 'firebase/database';
import Logo from '../assets/Settersuitelogosmall.png'; // Import the logo image
import { useTheme } from '@mui/material/styles';

Chart.register(LinearScale, CategoryScale, BarElement, ArcElement, ...registerables);

const Dashboard = () => {
  const theme = useTheme();
  const [userName, setUserName] = useState('');
  const [leadData, setLeadData] = useState({});
  const [leadDistributionData, setLeadDistributionData] = useState({});
  const [leadsBySourceData, setLeadsBySourceData] = useState({});
  const [anchorEls, setAnchorEls] = useState({
    leadStatus: null,
    leadsBySource: null,
    leadVolume: null,
  });
  const [chartTypes, setChartTypes] = useState({
    leadStatus: 'doughnut',
    leadsBySource: 'bar',
    leadVolume: 'line',
  });

  useEffect(() => {
  const fetchUserDataAndLeads = async () => {
    try {
      const currentUser = await getCurrentUser();
      setUserName(currentUser.name);

      const leadsRef = ref(database, 'leads');
      const leadDataSnapshot = await get(leadsRef);
      const allLeads = leadDataSnapshot.val();



      const visibleLeads = filterLeadsBasedOnUser(allLeads, currentUser);


      setLeadData(groupLeadsByMonth(visibleLeads));
      setLeadDistributionData(groupLeadsByCycle(visibleLeads));
      setLeadsBySourceData(groupLeadsBySource(visibleLeads));
    } catch (error) {
      console.error('Error fetching user data or leads:', error);
    }
  };

  fetchUserDataAndLeads();
}, []);

const filterLeadsBasedOnUser = (leads, user) => {
  if (!leads) return {};

  if (user.role === 'Super Admin') {

    return leads;
  } else if (user.role === 'Owner') {
    const filteredLeads = Object.fromEntries(
      Object.entries(leads).filter(([key, lead]) => lead.company === user.companyUmbrella)
    );

    return filteredLeads;
  } else if (user.role === 'User') {
    const filteredLeads = Object.fromEntries(
      Object.entries(leads).filter(([key, lead]) => lead.userAssigned === user.name)
    );

    return filteredLeads;
  } else {
    return {};
  }
};

const groupLeadsByMonth = (leads) => {
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const groupedLeads = {};

  for (let i = 0; i < 12; i++) {
    const targetDate = new Date(currentYear, currentMonth - i, 1);
    const year = targetDate.getFullYear();
    const monthIndex = targetDate.getMonth();
    const monthName = `${year}-${monthNames[monthIndex]}`;
    groupedLeads[monthName] = 0;
  }

  Object.values(leads).forEach((lead) => {
    const date = new Date(lead.createdAt);
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const monthName = `${year}-${monthNames[monthIndex]}`;
    if (groupedLeads[monthName] !== undefined) {
      groupedLeads[monthName]++;
    }
  });


  return groupedLeads;
};


const groupLeadsByCycle = (leads) => {
  const groupedLeads = {
    'New Lead': 0,
    'Enrolled': 0,
    'Qualified': 0,
  };
  Object.values(leads).forEach((lead) => {
    const cycle = lead.leadCycle;
    if (groupedLeads[cycle] !== undefined) {
      groupedLeads[cycle]++;
    }
  });

  return groupedLeads;
};

const groupLeadsBySource = (leads) => {
  const groupedLeads = {
    'Direct': 0,
    'Ads': 0,
    'Referral': 0,
    'Social Media': 0,
    'Events': 0,
  };
  Object.values(leads).forEach((lead) => {
    const source = lead.leadSource;
    if (groupedLeads[source] !== undefined) {
      groupedLeads[source]++;
    }
  });

  return groupedLeads;
};

const handleMenuOpen = (event, key) => {
  setAnchorEls((prevState) => ({
    ...prevState,
    [key]: event.currentTarget,
  }));
};

const handleMenuClose = (key) => {
  setAnchorEls((prevState) => ({
    ...prevState,
    [key]: null,
  }));
};

const handleChartTypeChange = (key, type) => {
  setChartTypes((prevState) => ({
    ...prevState,
    [key]: type,
  }));
  handleMenuClose(key);
};

return (
  <Box sx={{ display: 'flex', height: '100vh', backgroundColor: theme.palette.background.default }}>
    <Sidebar />
    
    <Box sx={{ flex: 1, padding: '20px', backgroundColor: theme.palette.background.default }}>
    
    <Typography
  variant="h4"
  gutterBottom
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center', // Added textAlign to center the text horizontally
    marginTop: '20px', // Added marginTop to give some space between the text and the top
  }}
>
  {userName ? `${userName}, Welcome to your Dashboard` : 'Welcome to your Dashboard'}
  
</Typography>

      <Divider sx={{ marginBottom: '20px' }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '300px', position: 'relative' }}>
            <Typography variant="h6" gutterBottom>
              Lead Distribution
            </Typography>
            <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
              <Button onClick={(event) => handleMenuOpen(event, 'leadStatus')} color="secondary" variant="outlined">More</Button>
              <Menu
                anchorEl={anchorEls.leadStatus}
                open={Boolean(anchorEls.leadStatus)}
                onClose={() => handleMenuClose('leadStatus')}
              >
                <MenuItem onClick={() => handleChartTypeChange('leadStatus', 'doughnut')}>Doughnut</MenuItem>
                <MenuItem onClick={() => handleChartTypeChange('leadStatus', 'bar')}>Bar</MenuItem>
                <MenuItem onClick={() => handleChartTypeChange('leadStatus', 'line')}>Line</MenuItem>
                <MenuItem onClick={() => handleChartTypeChange('leadStatus', 'pie')}>Pie</MenuItem>
              </Menu>
            </Box>
            {chartTypes.leadStatus === 'doughnut' && (
              <Doughnut
                data={{
                  labels: Object.keys(leadDistributionData),
                  datasets: [
                    {
                      data: Object.values(leadDistributionData),
                      backgroundColor: ['#d680ab','#e8b77b','#c7ecee', '#ff6384', '#36a2eb', '#ffce56', '#ff9f40', '#4bc0c0', '#9966ff', '#ffcc99', '#99ff99', '#ff99aa', '#ff9999', '#669999', '#cc99ff'],
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                      position: 'bottom',
                    },
                  },
                }}
              />
            )}
            {chartTypes.leadStatus === 'bar' && (
              <Bar
                data={{
                  labels: Object.keys(leadDistributionData),
                  datasets: [
                    {
                      label: 'Leads',
                      data: Object.values(leadDistributionData),
                      backgroundColor: '#c7ecee',
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      stacked: true,
                      beginAtZero: true,
                    },
                  },
                }}
              />
            )}
            {chartTypes.leadStatus === 'line' && (
              <Line
                data={{
                  labels: Object.keys(leadDistributionData),
                  datasets: [
                    {
                      label: 'Leads',
                      data: Object.values(leadDistributionData),
                      borderColor: '#c7ecee',
                      backgroundColor: 'transparent',
                      tension: 0.1,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      stacked: true,
                      beginAtZero: true,
                    },
                  },
                }}
              />
            )}
            {chartTypes.leadStatus === 'pie' && (
              <Pie
                data={{
                  labels: Object.keys(leadDistributionData),
                  datasets: [
                    {
                      data: Object.values(leadDistributionData),
                      backgroundColor: ['#d680ab','#e8b77b','#c7ecee','#ff6384', '#36a2eb', '#ffce56', '#ff9f40', '#4bc0c0', '#9966ff', '#ffcc99', '#99ff99', '#ff99aa', '#ff9999', '#669999', '#cc99ff'],
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                      position: 'bottom',
                    },
                  },
                }}
              />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '300px', position: 'relative' }}>
            <Typography variant="h6" gutterBottom>
              Leads By Source
            </Typography>
            <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
              <Button onClick={(event) => handleMenuOpen(event, 'leadsBySource')} color="secondary" variant="outlined">More</Button>
              <Menu
                anchorEl={anchorEls.leadsBySource}
                open={Boolean(anchorEls.leadsBySource)}
                onClose={() => handleMenuClose('leadsBySource')}
              >
                <MenuItem onClick={() => handleChartTypeChange('leadsBySource', 'doughnut')}>Doughnut</MenuItem>
                <MenuItem onClick={() => handleChartTypeChange('leadsBySource', 'bar')}>Bar</MenuItem>
                <MenuItem onClick={() => handleChartTypeChange('leadsBySource', 'line')}>Line</MenuItem>
                <MenuItem onClick={() => handleChartTypeChange('leadsBySource', 'pie')}>Pie</MenuItem>
              </Menu>
            </Box>
            {chartTypes.leadsBySource === 'doughnut' && (
              <Doughnut
                data={{
                  labels: Object.keys(leadsBySourceData),
                  datasets: [
                    {
                      data: Object.values(leadsBySourceData),
                      backgroundColor: ['#d680ab','#e8b77b','#c7ecee','#ff6384', '#36a2eb', '#ffce56', '#ff9f40', '#4bc0c0', '#9966ff', '#ffcc99', '#99ff99', '#ff99aa', '#ff9999', '#669999', '#cc99ff'],
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                      position: 'bottom',
                    },
                  },
                }}
              />
            )}
            {chartTypes.leadsBySource === 'bar' && (
              <Bar
                data={{
                  labels: Object.keys(leadsBySourceData),
                  datasets: [
                    {
                      label: 'Leads',
                      data: Object.values(leadsBySourceData),
                      backgroundColor: '#c7ecee',
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      stacked: true,
                      beginAtZero: true,
                    },
                  },
                }}
              />
            )}
            {chartTypes.leadsBySource === 'line' && (
              <Line
                data={{
                  labels: Object.keys(leadsBySourceData),
                  datasets: [
                    {
                      label: 'Leads',
                      data: Object.values(leadsBySourceData),
                      borderColor: '#c7ecee',
                      backgroundColor: 'transparent',
                      tension: 0.1,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      stacked: true,
                      beginAtZero: true,
                    },
                  },
                }}
              />
            )}
            {chartTypes.leadsBySource === 'pie' && (
              <Pie
                data={{
                  labels: Object.keys(leadsBySourceData),
                  datasets: [
                    {
                      data: Object.values(leadsBySourceData),
                      backgroundColor: ['#d680ab','#e8b77b','#c7ecee','#ff6384', '#36a2eb', '#ffce56', '#ff9f40', '#4bc0c0', '#9966ff', '#ffcc99', '#99ff99', '#ff99aa', '#ff9999', '#669999', '#cc99ff'],
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                      position: 'bottom',
                    },
                  },
                }}
              />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '300px', position: 'relative' }}>
              <Typography variant="h6" gutterBottom>
                Lead Volume Over Time
              </Typography>
              <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
                <Button onClick={(event) => handleMenuOpen(event, 'leadVolume')} color="secondary" variant="outlined">More</Button>
                <Menu
                  anchorEl={anchorEls.leadVolume}
                  open={Boolean(anchorEls.leadVolume)}
                  onClose={() => handleMenuClose('leadVolume')}
                >
                  <MenuItem onClick={() => handleChartTypeChange('leadVolume', 'line')}>Line</MenuItem>
                  <MenuItem onClick={() => handleChartTypeChange('leadVolume', 'bar')}>Bar</MenuItem>
                  <MenuItem onClick={() => handleChartTypeChange('leadVolume', 'doughnut')}>Doughnut</MenuItem>
                  <MenuItem onClick={() => handleChartTypeChange('leadVolume', 'pie')}>Pie</MenuItem>
                </Menu>
              </Box>
              {chartTypes.leadVolume === 'line' && (
                <Line
                  data={{
                    labels: Object.keys(leadData),
                    datasets: [
                      {
                        label: 'Leads',
                        data: Object.values(leadData),
                        borderColor: '#c7ecee',
                        backgroundColor: 'transparent',
                        tension: 0.1,
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        stacked: true,
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              )}
              {chartTypes.leadVolume === 'bar' && (
                <Bar
                  data={{
                    labels: Object.keys(leadData),
                    datasets: [
                      {
                        label: 'Leads',
                        data: Object.values(leadData),
                        backgroundColor: '#c7ecee',
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        stacked: true,
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              )}
              {chartTypes.leadVolume === 'doughnut' && (
                <Doughnut
                  data={{
                    labels: Object.keys(leadData),
                    datasets: [
                      {
                        data: Object.values(leadData),
                        backgroundColor: ['#d680ab','#e8b77b','#c7ecee','#ff6384', '#36a2eb', '#ffce56', '#ff9f40', '#4bc0c0', '#9966ff', '#ffcc99', '#99ff99', '#ff99aa', '#ff9999', '#669999', '#cc99ff'],
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: true,
                        position: 'bottom',
                      },
                    },
                  }}
                />
              )}
              {chartTypes.leadVolume === 'pie' && (
                <Pie
                  data={{
                    labels: Object.keys(leadData),
                    datasets: [
                      {
                        data: Object.values(leadData),
                        backgroundColor: ['#d680ab','#e8b77b','#c7ecee','#ff6384', '#36a2eb', '#ffce56', '#ff9f40', '#4bc0c0', '#9966ff', '#ffcc99', '#99ff99', '#ff99aa', '#ff9999', '#669999', '#cc99ff'],
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: true,
                        position: 'bottom',
                      },
                    },
                  }}
                />
              )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  </Box>
);

};

export default Dashboard;
