import React, { useState, useEffect } from 'react';
import { ref, onValue, update } from 'firebase/database';
import { database } from './Firebase';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Box, Typography, Divider, Button, Dialog, DialogTitle,
    DialogContent, DialogActions, Tabs, Tab, Grid
} from '@mui/material';
import Sidebar from './Sidebar';
import LeadEnrolledComparison from './LeadEnrolledComparison'; // Import the comparison component

const LeadEnrolled = () => {
    const [leads, setLeads] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [selectedLead, setSelectedLead] = useState(null);
    const [formSubmissions, setFormSubmissions] = useState([]);
    const [openAssignModal, setOpenAssignModal] = useState(false);
    const [currentSubmission, setCurrentSubmission] = useState(0);

    useEffect(() => {
        const leadsRef = ref(database, 'leads');
        onValue(leadsRef, (snapshot) => {
            const data = snapshot.val();
            const allLeads = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
            setLeads(allLeads);
        });
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleOpenAssignModal = (lead) => {
        setSelectedLead(lead);
        // Fetch all form submissions
        const submissionsRef = ref(database, 'submissions');
        onValue(submissionsRef, (snapshot) => {
            const data = snapshot.val();
            const allSubmissions = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
            setFormSubmissions(allSubmissions);
            setCurrentSubmission(0);
        });
        setOpenAssignModal(true);
    };

    const handleCloseAssignModal = () => {
        setOpenAssignModal(false);
        setSelectedLead(null);
        setFormSubmissions([]);
        setCurrentSubmission(0);
    };

    const handleNextSubmission = () => {
        setCurrentSubmission((prev) => (prev + 1) % formSubmissions.length);
    };

    const handleAssignSubmission = () => {
        const selectedSubmission = formSubmissions[currentSubmission];
        update(ref(database, `leads/${selectedLead.id}`), {
            ...selectedLead,
            ...selectedSubmission,
            leadCycle: 'Enrolled with Form'
        });
        handleCloseAssignModal();
    };

    const handleCreateNewLead = () => {
        const selectedSubmission = formSubmissions[currentSubmission];
        const newLeadRef = ref(database, 'leads');
        update(newLeadRef, {
            ...selectedSubmission,
            leadCycle: 'Enrolled'
        });
        handleCloseAssignModal();
    };

    const handleQualifyLead = (lead) => {
        update(ref(database, `leads/${lead.id}`), { leadCycle: 'Qualified' });
    };

    const renderObject = (obj) => {
        return Object.keys(obj).map((key) => (
            <Typography key={key}>{key}: {typeof obj[key] === 'object' ? JSON.stringify(obj[key]) : obj[key]}</Typography>
        ));
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <Sidebar />
            <Box sx={{ flex: 1, padding: '20px' }}>
                <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '20px' }}>
                    Enrolled Leads
                </Typography>
                <Divider sx={{ marginBottom: '20px' }} />
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Enrolled" />
                    <Tab label="Enrolled with Form Submissions" />
                </Tabs>
                {tabValue === 0 && (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Company</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leads.filter(lead => lead.leadCycle === 'Enrolled').map((lead) => (
                                    <TableRow key={lead.id}>
                                        <TableCell>{lead.name}</TableCell>
                                        <TableCell>{lead.email}</TableCell>
                                        <TableCell>{lead.phone}</TableCell>
                                        <TableCell>{lead.company}</TableCell>
                                        <TableCell>
                                            <Button variant="contained" color="primary" onClick={() => handleOpenAssignModal(lead)}>Assign Form</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {tabValue === 1 && (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Company</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leads.filter(lead => lead.formValues).map((lead) => (
                                    <TableRow key={lead.id}>
                                        <TableCell>{lead.name}</TableCell>
                                        <TableCell>{lead.email}</TableCell>
                                        <TableCell>{lead.phone}</TableCell>
                                        <TableCell>{lead.company}</TableCell>
                                        <TableCell>
                                            <Button variant="contained" color="primary" onClick={() => handleQualifyLead(lead)}>Qualify</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <Dialog open={openAssignModal} onClose={handleCloseAssignModal} maxWidth="lg" fullWidth>
                    <DialogTitle>Assign Form</DialogTitle>
                    <DialogContent>
                        {formSubmissions.length > 0 ? (
                            <LeadEnrolledComparison
                                lead={selectedLead}
                                submission={formSubmissions[currentSubmission]}
                            />
                        ) : (
                            <Typography>No form submissions available.</Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAssignModal}>Cancel</Button>
                        <Button onClick={handleNextSubmission}>Next</Button>
                        <Button onClick={handleAssignSubmission} disabled={formSubmissions.length === 0}>Assign</Button>
                        <Button onClick={handleCreateNewLead} disabled={formSubmissions.length === 0}>Create New</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
};

export default LeadEnrolled;
