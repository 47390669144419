import React, { useState, useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Box,
  Grid,
  Paper,
  TextField,
  MenuItem,
  IconButton,
  useMediaQuery,
  Card,
  CardContent,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PeopleIcon from '@mui/icons-material/People';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ScaleIcon from '@mui/icons-material/Scale';
import ImageCarousel from './ImageCarousel';
import '../HomePage.css';
import Logo from '../assets/SetterSuiteLogoNew.png';

const Root = styled('div')({
  flexGrow: 1,
  minHeight: '100vh',
  background: 'off-white',
  position: 'relative',
  overflow: 'hidden',
});

const WaveContainer = styled('div')(({ scrollPosition }) => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 1,
  
  overflow: 'hidden',
}));

const Wave = styled('div')(({ theme, index }) => ({
  position: 'absolute',
  top: '60vw',
  left: '50%',
  width: '100vw',
  height: '100vw',
  transform: 'translate(-50%, -50%)',
  animation: 'wave 18s infinite linear',
  animationDelay: `${index * 2}s`,
  opacity: 0.3,
  background: `linear-gradient(
    45deg,
    #d3d3d3 ${index * 10}%,
    #a9a9a9 ${50 + index * 10}%,
    #d3d3d3 ${100 - index * 10}%
  )`,
  borderRadius: '43%',
  '@keyframes wave': {
    '0%': {
      transform: 'translate(-50%, -50%) rotate(0deg)',
    },
    '100%': {
      transform: 'translate(-50%, -50%) rotate(360deg)',
    },
  },
}));

const ContentContainer = styled('div')({
  position: 'relative',
  zIndex: 2,
});

const Title = styled(Typography)({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-start', // Align text to the left
  '& .title-letter': {
    display: 'inline-block',
  },
});

const Hero = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  background: 'none',
  color: '#000',
  textAlign: 'center',
  padding: theme.spacing(4),
  paddingTop: theme.spacing(10), // Add padding to account for fixed AppBar
}));

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  '&:hover .title-letter': {
    animation: 'vaporwave 2s linear infinite',
  },
  // '@keyframes vaporwave': {
  //   '0%': { color: '#da257f' },
  //   '20%': { color: '#ff7e5f' },
  //   '40%': { color: '#feb47b' },
  //   '60%': { color: '#eddfb8' },
  //   '80%': { color: '#c7ecee' },
  //   '100%': { color: '#da257f' },
  // },
  position: 'fixed',
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  width: '100%',
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const InfoItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(0),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const InfoItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(8),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  margin: theme.spacing(1),
  transition: 'transform 0.3s, z-index 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    zIndex: 3,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(8),
  textAlign: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  width: '100%',
  marginTop: '20%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const SectionContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  color: '#000000',
}));

const ModernSectionContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(12, 0),
  color: '#000000',
  background: '',
}));

const InfoCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const QuoteContainer = styled(Box)(({ theme }) => ({
  maxWidth: '800px',
  margin: '0 auto',
  padding: theme.spacing(4),
}));

const ModernCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
  },
}));

const HomePage = () => {
  const form = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    //lineOfWork: '',
    companyName: '',
    about: '',
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //navigate('/schedule-demo', { state: { formValues } });

    emailjs
      .sendForm(
        'service_4v76izc', // Replace with your EmailJS service ID
        'template_ewa0n8c', // Replace with your EmailJS template ID
        form.current,
        {
          publicKey: 'DA6UXBCOWjsNrrWDM', // Replace with your EmailJS public key
        }
      )
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          alert('Thank you for your interest. We will contact you soon!');
          // Clear the form
          setFormValues({
            name: '',
            email: '',
            phone: '',
            //lineOfWork: '',
            companyName: '',
            about: '',
          });
        },
        (error) => {
          console.error('Error sending email:', error.text);
          alert('There was an error sending your request. Please try again later.');
        }
      );
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);  

  

  return (
    <Root>
       <WaveContainer>
        <Wave index={0} style={{ transform: `translate(-50%, -50%) translateY(${scrollPosition * 0.1}px) rotate(0deg)` }} />
        <Wave index={1} style={{ transform: `translate(-50%, -50%) translateY(${scrollPosition * 0.15}px) rotate(0deg)` }} />
        <Wave index={2} style={{ transform: `translate(-50%, -50%) translateY(${scrollPosition * 0.2}px) rotate(0deg)` }} />
      </WaveContainer>
      <ContentContainer>
      <CustomAppBar>
        <Toolbar>
          <Title variant="h6" color="black">
          <img src={Logo} alt="logo" style={{ width: 'auto', height: '50px' }} />
          </Title>
          <Box sx={{ flexGrow: 1 }} />
            <Button component={RouterLink} to="/pricing" color="inherit">Pricing</Button>
            <Button component={RouterLink} to="/faq" color="inherit">FAQs</Button>
            <Button component={RouterLink} to="/login" variant="outlined" size="large" style={{ color: 'black', marginLeft: '16px' }}>
            Sign In
          </Button>
        </Toolbar>
      </CustomAppBar>
      <Hero>
          <QuoteContainer>
            <Typography variant="h3" gutterBottom>
              "You have leads, let us qualify them so you can have opportunity at a fraction of the cost!"
            </Typography>
          </QuoteContainer>
          <Grid item xs={4}>
            <FormContainer>
              <Typography variant="h6" gutterBottom>
                Schedule a Demo
              </Typography>
              <form ref={form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      label="Name"
                      variant="outlined"
                      name="name" // Changed to match EmailJS template was name
                      value={formValues.name}
                      onChange={handleInputChange}
                      InputProps={{
                        startAdornment: (
                          <IconButton position="start">
                            <PersonIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      label="Email"
                      variant="outlined"
                      name="email" // Changed to match EmailJS template was email
                      value={formValues.email}
                      onChange={handleInputChange}
                      InputProps={{
                        startAdornment: (
                          <IconButton position="start">
                            <EmailIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      label="Phone Number"
                      variant="outlined"
                      name="phone" // Changed to match EmailJS template was phone
                      value={formValues.phone}
                      onChange={handleInputChange}
                      InputProps={{
                        startAdornment: (
                          <IconButton position="start">
                            <PhoneIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      label="Line of Work"
                      variant="outlined"
                      name="lineOfWork" // Changed to match EmailJS template was lineOfWork
                      value={formValues.lineOfWork}
                      select
                      InputProps={{
                        startAdornment: (
                          <IconButton position="start">
                            <BusinessIcon />
                          </IconButton>
                        ),
                      }}
                    >
                      <MenuItem value="IT">IT</MenuItem>
                      <MenuItem value="Marketing">Marketing</MenuItem>
                      <MenuItem value="Sales">Sales</MenuItem>
                      <MenuItem value="Finance">Finance</MenuItem>
                      <MenuItem value="HR">HR</MenuItem>
                    </TextField>
                  </Grid> */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Company Name"
                      variant="outlined"
                      name="companyName" // Changed to match EmailJS template was companyName
                      value={formValues.companyName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Tell us about your company"
                      variant="outlined"
                      name="about" // Changed to match EmailJS template was companyName
                      value={formValues.about}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" type="submit" color="primary">
                      Schedule Demo
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </FormContainer>
          </Grid>
        </Hero>

        <SectionContainer>
          <Typography variant="h2" align="center" gutterBottom>
            Inside The App
          </Typography>
          <ImageCarousel />
        </SectionContainer>

        <SectionContainer>
          <Typography variant="h2" align="center" gutterBottom>
            Why Choose SetterSuite?
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {[
              { icon: CurrencyExchangeIcon, title: "Cost-effective Solution", description: "Get Qualified Leads without the hassle", color: '#4caf50' },
              { icon: DesignServicesIcon, title: "Tailor-made Design", description: "We've got the expertise to tailor our info to reach your customers", color: '#2196f3' },
              { icon: ScaleIcon, title: "Scalable as you grow", description: "We are here to meet your needs, grow with us", color: '#ff9800' },
              { icon: PeopleIcon, title: "Generate Quality Leads", description: "Use our platform to turn your potential leads into high-quality leads for your business.", color: '#9c27b0' },
              { icon: TrendingUpIcon, title: "Increase Your Revenue", description: "Our tools convert potential leads to qualified customers and boost your revenue.", color: '#f44336' },
              { icon: CurrencyExchangeIcon, title: "Reduce Overhead Cost", description: "We will qualify the leads for you. That is money back in your pocket!", color: '#009688' },
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <ModernCard>
                  <CardContent>
                    <item.icon style={{ fontSize: 60, color: item.color }} />
                    <Typography variant="h5" gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography variant="body1">
                      {item.description}
                    </Typography>
                  </CardContent>
                </ModernCard>
              </Grid>
            ))}
          </Grid>
        </SectionContainer>
          
      <ModernSectionContainer>
        <Typography variant="h2" align="center" gutterBottom>
          About Us
        </Typography>
        <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={8}>
        <Typography variant="body1" align="center" paragraph>
          SetterSuite is a cutting-edge platform designed to revolutionize lead qualification and management. 
          Our mission is to empower businesses with innovative tools that streamline the process of 
          identifying, qualifying, and converting potential leads into valuable customers.
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          With our state-of-the-art technology and user-friendly interface, we aim to reduce overhead costs, 
          increase efficiency, and ultimately boost your revenue. SetterSuite is more than just a tool; 
          it's your partner in growth and success.
        </Typography>
        </Grid>
        </Grid>
      </ModernSectionContainer>
                
      <SectionContainer>
        <Typography variant="h2" align="center" gutterBottom>
          
        </Typography>
        
      </SectionContainer>
      </ContentContainer>
    </Root>
  );
};

export default HomePage;
