import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

const CreditsDisplay = ({ credits, loading, company }) => {
  return (
    <Box position="absolute" top={16} right={16} display="flex" alignItems="center">
      {loading ? (
        <CircularProgress size={20} />
      ) : company ? (
        <>
          <Typography variant="body1" mr={1}>
            {company} Credits:
          </Typography>
          <Typography variant="h6">{credits}</Typography>
        </>
      ) : (
        <Typography variant="body1">No Company Information Available</Typography>
      )}
    </Box>
  );
};

export default CreditsDisplay;
