import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Sidebar from './Sidebar';

const FAQ = () => {
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {/* Sidebar */}
      

      {/* Main Content */}
      <Box sx={{ flex: 1, padding: '20px', backgroundColor: '#f0f2f5' }}>
        {/* Header */}
        
        <Typography
  variant="h4"
  gutterBottom
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center', // Added textAlign to center the text horizontally
    marginTop: '20px', // Added marginTop to give some space between the text and the top
  }}
>
            Frequently Asked Questions
          </Typography>
        
        

        {/* Grid of Buttons */}
        <Box sx={{ marginBottom: '20px' }}>
          <Button href="#category1" variant="contained">Logging In</Button>{' '}
          <Button href="#category2" variant="contained">Coming Soon</Button>{' '}
          <Button href="#category3" variant="contained">Coming Soon</Button>{' '}
          <Button href="#category4" variant="contained">Coming Soon</Button>{' '}
          <Button href="#category5" variant="contained">Coming Soon</Button>{' '}
          <Button href="#category6" variant="contained">Coming Soon</Button>
        </Box>

        {/* Category 1 */}
        <Typography variant="h5" id="category1" gutterBottom>
          Loggin in
        </Typography>

        <Accordion id="category1-question1">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question1-content"
            id="category1-question1-header"
          >
            <Typography variant="h6">Why Can't I log in?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              It could be due to a few different reasons. You might not have login credentials yet. If you did, then it is possible that your account has been deactivated.
              If you are looking to sign up feel free to schedule a demo and see if this is right for you.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion id="category1-question2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question2-content"
            id="category1-question2-header"
          >
            <Typography variant="h6">Question 2: Why do we use it?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              It is a long established fact that a reader will be distracted by the readable content of a page when
              looking at its layout.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion id="category1-question3">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question3-content"
            id="category1-question3-header"
          >
            <Typography variant="h6">Question 3: Where does it come from?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical
              Latin literature from 45 BC.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Category 2 */}
        <Typography variant="h5" id="category2" gutterBottom>
          Category 2
        </Typography>
        <Accordion id="category1-question1">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question1-content"
            id="category1-question1-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion id="category1-question2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question2-content"
            id="category1-question2-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion id="category1-question3">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question3-content"
            id="category1-question3-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* Accordion items for Category 2 */}
        {/* Add three Accordion items similar to Category 1 */}

        {/* Category 3 */}
        <Typography variant="h5" id="category3" gutterBottom>
        Coming Soon
        </Typography>
        <Accordion id="category1-question1">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question1-content"
            id="category1-question1-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion id="category1-question2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question2-content"
            id="category1-question2-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion id="category1-question3">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question3-content"
            id="category1-question3-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* Accordion items for Category 3 */}
        {/* Add three Accordion items similar to Category 1 */}

        {/* Category 4 */}
        <Typography variant="h5" id="category4" gutterBottom>
        Coming Soon
        </Typography>
        <Accordion id="category1-question1">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question1-content"
            id="category1-question1-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion id="category1-question2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question2-content"
            id="category1-question2-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion id="category1-question3">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question3-content"
            id="category1-question3-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* Accordion items for Category 4 */}
        {/* Add three Accordion items similar to Category 1 */}

        {/* Category 5 */}
        <Typography variant="h5" id="category5" gutterBottom>
        Coming Soon
        </Typography>
        <Accordion id="category1-question1">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question1-content"
            id="category1-question1-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion id="category1-question2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question2-content"
            id="category1-question2-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion id="category1-question3">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question3-content"
            id="category1-question3-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* Accordion items for Category 5 */}
        {/* Add three Accordion items similar to Category 1 */}

        {/* Category 6 */}
        <Typography variant="h5" id="category6" gutterBottom>
        Coming Soon
        </Typography>
        <Accordion id="category1-question1">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question1-content"
            id="category1-question1-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion id="category1-question2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question2-content"
            id="category1-question2-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion id="category1-question3">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="category1-question3-content"
            id="category1-question3-header"
          >
            <Typography variant="h6">Coming Soon?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Coming Soon
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* Accordion items for Category 6 */}
        {/* Add three Accordion items similar to Category 1 */}
      </Box>
    </Box>
  );
};

export default FAQ;
