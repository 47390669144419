import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './Firebase';
import axios from 'axios'; // Import axios for making HTTP requests
import { UserContext } from './UserContext';

const Logout = () => {
  const navigate = useNavigate();
  const { clearUser } = useContext(UserContext);

  const handleLogout = async () => {
    try {
      const user = auth.currentUser;
      const apiUrl = process.env.REACT_APP_API_URL.replace(/\/$/, '');
      if (user) {
        // Log the logout event by sending a request to your server
        await axios.post(`${apiUrl}/logout`, { userId: user.uid });
      }
      await auth.signOut(); // Sign out the user from Firebase
      clearUser();
      navigate('/'); // Redirect to the login screen
    } catch (error) {
      console.error('Error logging out:', error.response ? error.response.data : error.message);
      alert('Logout failed. Please check the console for more details.');
    }
  };

  // Automatically trigger logout logic when component mounts
  useEffect(() => {
    handleLogout();
  }, []);

  // This component doesn't need to render anything
  return null;
};

export default Logout;