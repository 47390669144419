import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import getTheme from './theme';
import { CreditsProvider } from './components/CreditsContext';
import { UserProvider } from './components/UserContext';
import PrivateRoute from './components/PrivateRoute';

// Import all your components here
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Map from './components/Map';
import Enrolled from './components/LeadEnrolled';
import LeadListing from './components/LeadListing';
import Reports from './components/Reports';
import UserListing from './components/UserListing';
import CompanyListing from './components/CompanyListing';
import Profile from './components/Profile';
import ArchivedLeads from './components/ArchivedLeads';
import AdminSettings from './components/AdminSettings';
import Integrations from './components/IntegrationsPage';
import Billing from './components/Billing';
import FAQ from './components/Faq';
import Logout from './components/Logout';
import HomePage from './components/HomePage';
import ScheduleDemo from './components/ScheduleDemo';
import DemoConfirmed from './components/DemoConfirmed';
import CompanyDetail from './components/CompanyDetail';
import CustomerForm from './components/CustomerForm';
import ThankYouPage from './components/ThankYouPage';
import CustomerPricing from './components/CustomerPricing';


export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {
  const [mode, setMode] = useState('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(() => getTheme(mode), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserProvider>
        <CreditsProvider>
          <Router>
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<HomePage />} />
              <Route path="/schedule-demo" element={<ScheduleDemo />} />
              <Route path="/demo-confirmed" element={<DemoConfirmed />} />
              <Route path="/login" element={<Login />} />
              <Route path="/pricing" element={<CustomerPricing />} />
              <Route path="/:companyId/form" element={<CustomerForm />} />
              <Route path="/thank-you" element={<ThankYouPage />} />
              <Route path="/faq" element={<FAQ />} />

              {/* Protected routes */}
              <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="/map" element={<PrivateRoute><Map /></PrivateRoute>} />
              <Route path="/enrolled" element={<PrivateRoute><Enrolled /></PrivateRoute>} />
              <Route path="/leads" element={<PrivateRoute><LeadListing /></PrivateRoute>} />
              <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />
              <Route path="/users" element={<PrivateRoute><UserListing /></PrivateRoute>} />
              <Route path="/companies" element={<PrivateRoute><CompanyListing /></PrivateRoute>} />
              <Route path="/companies/:id" element={<PrivateRoute><CompanyDetail /></PrivateRoute>} />
              <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
              <Route path="/archived" element={<PrivateRoute><ArchivedLeads /></PrivateRoute>} />
              <Route path="/admin-settings/*" element={<PrivateRoute><AdminSettings /></PrivateRoute>} />
              <Route path="/integrations" element={<PrivateRoute><Integrations /></PrivateRoute>} />
              <Route path="/billing" element={<PrivateRoute><Billing /></PrivateRoute>} />
              
              <Route path="/logout" element={<PrivateRoute><Logout /></PrivateRoute>} />
            </Routes>
          </Router>
        </CreditsProvider>
      </UserProvider>
    </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;