import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get, query, orderByChild, startAt, endAt, set, runTransaction, update, push, equalTo, onValue, off } from 'firebase/database';
import { getFirestore, collection, addDoc, getDocs, query as firestoreQuery, where, getDoc, doc, updateDoc, setDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage'; // Add this import

const firebaseConfig = {
    apiKey: "AIzaSyCgeM9f3t5cC8-HF6_G8l3mHFFg0TIYT80",
    authDomain: "leadqualify-58b43.firebaseapp.com",
    databaseURL: "https://leadqualify-58b43-default-rtdb.firebaseio.com/",
    projectId: "leadqualify-58b43",
    storageBucket: "leadqualify-58b43.appspot.com",
    messagingSenderId: "759317799637",
    appId: "1:759317799637:web:e407dd6747ec4e6cd5f85c",
    measurementId: "G-RZQKW14EC4"
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const database = getDatabase(app);
  const auth = getAuth(app);
  const db = getFirestore(app);
  const storage = getStorage(app);
  
  export const fetchCompanies = async () => {
    try {
        const companiesSnapshot = await get(ref(database, 'companies'));
        const companiesData = [];
        companiesSnapshot.forEach((company) => {
            companiesData.push(company.val());
        });
        return companiesData;
    } catch (error) {
        console.error('Error fetching companies:', error);
    }
  };
  
  export const fetchCompaniesByName = async (name) => {
    try {
        const companiesRef = ref(database, 'companies');
        const nameQuery = query(companiesRef, orderByChild('name'), startAt(name), endAt(name + '\uf8ff'));
        const snapshot = await get(nameQuery);
        return snapshot.exists() ? Object.values(snapshot.val()) : [];
    } catch (error) {
        console.error('Error fetching companies by name:', error);
    }
  };
  
  export const fetchUsersByCompany = async (companyName) => {
    try {
        const q = firestoreQuery(collection(db, 'users'), where('companyUmbrella', '==', companyName));
        const querySnapshot = await getDocs(q);
        const usersList = [];
        querySnapshot.forEach((doc) => {
            usersList.push({ id: doc.id, ...doc.data() });
        });
        return usersList;
    } catch (error) {
        console.error('Error fetching users by company:', error);
    }
  };
  
  const fetchCompanyIdByName = async (companyName) => {
  try {
    const companiesRef = ref(database, `companies`);
    const q = query(companiesRef, orderByChild('name'), startAt(companyName), endAt(companyName + '\uf8ff'));
    const snapshot = await get(q);
    
    console.log(`Query snapshot exists: ${snapshot.exists()}`);
    
    if (snapshot.exists()) {
      const data = snapshot.val();
      const companyId = Object.keys(data)[0];
      console.log(`Company ID: ${companyId}`);
      return companyId;
    } else {
      console.error(`No company found with name: ${companyName}`);
      return null;
    }
  } catch (error) {
    console.error('Error fetching company ID by name:', error);
    return null;
  }
  };
  
  export const updateCompanyCredits = async (companyName, newCredits) => {
    try {
      // Fetch the company ID using the company name
      const companyId = await fetchCompanyIdByName(companyName);
      if (!companyId) {
        console.error(`No company ID found for name: ${companyName}`);
        throw new Error(`No company ID found for name: ${companyName}`);
      }
  
      // Reference to the company's data in the database
      const companyRef = ref(database, `companies/${companyId}`);
      const snapshot = await get(companyRef);
  
      if (snapshot.exists()) {
        const companyData = snapshot.val();
        const currentCredits = companyData.credits || 0;
  
        const creditsChangeLogs = Array.isArray(companyData.creditsChangeLogs) 
          ? companyData.creditsChangeLogs 
          : [];
  
        const newCreditsValue = currentCredits + newCredits;
        const newLog = {
          timestamp: new Date().toISOString(),
          change: newCredits,
          updatedCredits: newCreditsValue,
        };
        creditsChangeLogs.push(newLog);
  
        // Update the company's credits and logs in the database
        await update(companyRef, {
          credits: newCreditsValue,
          creditsChangeLogs,
        });
  
        console.log(`Credits updated successfully for company with ID: ${companyId}`);
      } else {
        console.error(`Company with ID ${companyId} not found`);
        throw new Error(`Company with ID ${companyId} not found`);
      }
    } catch (error) {
      console.error('Error updating company credits:', error);
      throw error;
    }
  };
  
  // This function fetches credits for a specific company ID

  export const fetchCompanyCredits = async (companyName, setCredits) => {
    try {
      const companyId = await fetchCompanyIdByName(companyName);
      if (companyId) {
        const creditsRef = ref(database, `companies/${companyId}/credits`);
        const unsubscribe = onValue(creditsRef, (snapshot) => {
          const credits = snapshot.exists() ? snapshot.val() : 0;
          console.log(`Real-time credits for company ID ${companyId}: ${credits}`);
          setCredits(credits);
        });
        return () => off(creditsRef); // Correctly return the unsubscribe function
      } else {
        console.error(`No company ID found for name: ${companyName}`);
        setCredits(0);
        return () => {}; // Return a no-op function if no company ID found
      }
    } catch (error) {
      console.error('Error fetching company credits:', error);
      setCredits(0);
      return () => {}; // Return a no-op function in case of error
    }
  };
  
  
  export const createUser = async (userData) => {
  try {
    const { email, password, ...rest } = userData;
  
    // Ensure email and password are provided
    if (!email || !password) {
      throw new Error('Email and password are required');
    }
  
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
  
    await sendEmailVerification(user);
  
    await setDoc(doc(db, 'users', user.uid), {
      uid: user.uid,
      email: user.email,
      ...rest,
    });
  
    // Log the user creation
    await addDoc(collection(db, 'logs'), {
      action: 'createUser',
      timestamp: new Date().toISOString(),
      createdBy: userData.createdBy,
      user: {
        uid: user.uid,
        email: user.email,
        ...rest,
      }
    });
    
  
    return user;
  } catch (error) {
    console.error('Error creating user:', error.message);
    throw error;
  }
  };
  
  export const getCurrentUser = async () => {
    const user = auth.currentUser;
    if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
            return { ...userDoc.data(), id: user.uid };
        } else {
            throw new Error('User document does not exist');
        }
    } else {
        throw new Error('User not authenticated');
    }
  };
  
  export const fetchUsersFromFirestore = async (currentUser) => {
    if (!currentUser) {
        throw new Error('Current user is not defined');
    }
  
    const usersCollection = collection(db, 'users');
    let q;
    if (currentUser.role === 'Super Admin') {
        q = firestoreQuery(usersCollection);
    } else if (currentUser.role === 'Owner') {
        q = firestoreQuery(usersCollection, where('companyUmbrella', '==', currentUser.companyUmbrella));
    } else {
        return [];
    }
  
    const querySnapshot = await getDocs(q);
    const users = [];
    querySnapshot.forEach((doc) => {
        users.push({ id: doc.id, ...doc.data() });
    });
  
    return users;
  };
  
  export const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
    } catch (error) {
        console.error('Error sending password reset email:', error);
    }
  };
  
  
  export const updateUserDetails = async (user) => {
    try {
        const userRef = doc(db, 'users', user.id);
        await updateDoc(userRef, {
            name: user.name,
            email: user.email,
            username: user.username,
            phone: user.phone,
            companyUmbrella: user.companyUmbrella,
            role: user.role,
        });
    } catch (error) {
        console.error('Error updating user details:', error);
    }
  };
  
  export const deactivateUser = async (userId) => {
    try {
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, { active: false });
    } catch (error) {
        console.error('Error deactivating user:', error);
    }
  };
  
  export { auth, database, db, analytics, storage };