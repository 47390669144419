import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import HomeScheduling from './HomeScheduling';
import APIIntegrations from './APIIntegrations';
import CompanyCredits from './CompanyCredits';
import Testing from './Testing';
import {
  Box, Typography, Divider, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem
} from '@mui/material';
import ListItem from '@mui/material/ListItem';

const AdminSettings = () => {
  return (
    <div>
      <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '50px' }}>Admin Settings</Typography>
      <Divider sx={{ marginBottom: '20px' }} />
      <nav>

      <ListItem><Link to="home-scheduling">Home Scheduling</Link></ListItem>
          <ListItem><Link to="api-integrations">API Integrations</Link></ListItem>
          <ListItem><Link to="company-credits">Company Credits</Link></ListItem>
          <ListItem><Link to="testing">Testing</Link></ListItem>
          
      </nav>
      <Routes>
        <Route path="home-scheduling" element={<HomeScheduling />} />
        <Route path="api-integrations" element={<APIIntegrations />} />
        <Route path="company-credits" element={<CompanyCredits />} />
        <Route path="testing" element={<Testing />} />
      </Routes>
    </div>
  );
};

export default AdminSettings;
