import React, { useState, useEffect, useContext } from 'react';
import {
    Box, Typography, Divider, Button, Menu, MenuItem,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, IconButton
} from '@mui/material';
import { Add as AddIcon, ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon } from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { update, ref, onValue, push, remove, set } from 'firebase/database';
import Sidebar from './Sidebar';
import LeadForm from './LeadForm';
import BulkUploadForm from './BulkUploadForm';
import { database, getCurrentUser, fetchCompanies, fetchUsersByCompany } from './Firebase';
import EditLeadModal from './EditLeadModal';
import CustomerDetailsModal from './CustomerDetailsModal';
import { CreditsContext } from './CreditsContext';
import CreditsDisplay from './CreditsDisplay';
import Logo from '../assets/Settersuitelogosmall.png'; // Import the logo image
import RefreshIcon from '@mui/icons-material/Refresh';


const LeadListing = () => {
    const [leads, setLeads] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openLeadForm, setOpenLeadForm] = useState(false);
    const [openBulkUploadForm, setOpenBulkUploadForm] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [loadingUser, setLoadingUser] = useState(true);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [companies, setCompanies] = useState([]);
    const [users, setUsers] = useState({});
    const [selectedCompany, setSelectedCompany] = useState('');
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedLead, setSelectedLead] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const { credits, updateCredits, refreshCredits, loading, companyName } = useContext(CreditsContext);

    useEffect(() => {
        console.log('Credits:', credits);
        console.log('Loading:', loading);
        console.log('User Company:', companyName);
      }, [credits, loading, companyName]);

    useEffect(() => {
        const fetchData = async () => {
            const user = await getCurrentUser();
            setCurrentUser(user);
            setLoadingUser(false);

            const fetchedCompanies = await fetchCompanies();
            setCompanies(fetchedCompanies);

            const leadsRef = ref(database, 'leads');
            const unsubscribe = onValue(leadsRef, async (snapshot) => {
                const data = snapshot.val();
                const leadsArray = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];

                const filteredLeads = leadsArray.filter(lead => {
                    if (user.role === 'Super Admin') {
                        return true;
                    } else if (user.role === 'Owner' && lead.company === user.companyUmbrella) {
                        return true;
                    } else if (user.role === 'User' && lead.userAssigned === user.name) {
                        return true;
                    }
                    return false;
                });

                const usersByCompany = {};
                await Promise.all(
                    filteredLeads.map(async (lead) => {
                        if (lead.company && !usersByCompany[lead.company]) {
                            const fetchedUsers = await fetchUsersByCompany(lead.company);
                            usersByCompany[lead.company] = fetchedUsers;
                        }
                    })
                );

                setLeads(filteredLeads);
                setUsers(usersByCompany);
            });

            return () => {
                unsubscribe();
            };
        };

        fetchData();
    }, []);

    const updateLeadAddresses = async (leads) => {
        for (const lead of leads) {
            if (!lead.latitude || !lead.longitude) {
                const { latitude, longitude } = await geocodeAddress(`${lead.street}, ${lead.state}`);
                if (latitude && longitude) {
                    const leadRef = ref(database, `leads/${lead.id}`);
                    await update(leadRef, { latitude, longitude });
                }
            }
        }
    };

    const geocodeAddress = async (address) => {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&limit=1`);
            const data = await response.json();
            if (data.length > 0) {
                const { lat, lon } = data[0];
                return { latitude: lat, longitude: lon };
            }
        } catch (error) {
            console.error('Error geocoding address:', error);
        }
        return { latitude: null, longitude: null };
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAddNewLead = () => {
        setOpenLeadForm(true);
        handleMenuClose();
    };

    const handleBulkUploadLeads = () => {
        setOpenBulkUploadForm(true);
        handleMenuClose();
    };

    const handleCloseLeadForm = () => {
        setOpenLeadForm(false);
    };

    const handleCloseBulkUploadForm = () => {
        setOpenBulkUploadForm(false);
    };

    const handleAddLead = async (newLead) => {
        try {
            console.log('Adding new lead:', newLead);
            newLead.leadCycle = 'New Lead';
            newLead.createdAt = new Date().toISOString();

            const { latitude, longitude } = await geocodeAddress(`${newLead.street}, ${newLead.state}`);
            console.log('Geocoded address:', { latitude, longitude });
            if (latitude && longitude) {
                newLead.latitude = latitude;
                newLead.longitude = longitude;
            }

            const leadsRef = ref(database, 'leads');
            await push(leadsRef, newLead);
            console.log('Lead added successfully');
        } catch (error) {
            console.error('Error adding lead:', error);
        }
    };

    const handleBulkUpload = async (bulkLeads) => {
        const leadsRef = ref(database, 'leads');
        for (const newLead of bulkLeads) {
            newLead.leadSource = 'Direct';
            newLead.leadCycle = 'New Lead';
            newLead.createdAt = new Date().toISOString();

            const { latitude, longitude } = await geocodeAddress(`${newLead.street}, ${newLead.state}`);
            newLead.latitude = latitude;
            newLead.longitude = longitude;

            push(leadsRef, newLead);
        }
    };

    const handleEditLead = (lead) => {
        setSelectedLead(lead);
        setEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setEditModalOpen(false);
        setSelectedLead(null);
    };

    const handleSaveLead = async (updatedLead) => {
        const leadRef = ref(database, `leads/${updatedLead.id}`);
        await update(leadRef, updatedLead);
        setLeads((prevLeads) =>
            prevLeads.map((lead) => (lead.id === updatedLead.id ? updatedLead : lead))
        );
    };

    const archiveLead = async (lead) => {
        const leadRef = ref(database, `leads/${lead.id}`);
        const archivedLeadsRef = ref(database, 'archivedLeads');
        const newArchivedLeadRef = push(archivedLeadsRef);
        await set(newArchivedLeadRef, lead);
        await remove(leadRef);
    };

    const handleSort = (field) => {
        if (sortBy === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(field);
            setSortOrder('asc');
        }
    };

    const sortedLeads = leads.sort((a, b) => {
        if (a.leadCycle === 'Qualified' && b.leadCycle !== 'Qualified') {
            return -1;
        }
        if (a.leadCycle !== 'Qualified' && b.leadCycle === 'Qualified') {
            return 1;
        }
        if (!sortBy) return 0;
        if (sortOrder === 'asc') {
            return a[sortBy] > b[sortBy] ? 1 : -1;
        } else {
            return a[sortBy] < b[sortBy] ? 1 : -1;
        }
    });

    const getSortIcon = (field) => {
        if (sortBy !== field) return null;
        return sortOrder === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />;
    };

    const tableHeaderCellStyle = {
        cursor: 'pointer',
        fontWeight: 'bold',
        backgroundColor: '#e0e0e0',
        '&:hover': {
            backgroundColor: '#f2f2f2',
        },
        padding: '10px',
    };

    const handleOpenCustomerModal = (customer) => {
        setSelectedCustomer(customer);
        setModalOpen(true);
    };

    const handleCloseCustomerModal = () => {
        setSelectedCustomer(null);
        setModalOpen(false);
    };

    const handleEnrollLead = async (leadId) => {
        if (credits > 0) {
            await update(ref(database, `leads/${leadId}`), { leadCycle: 'Enrolled' });
            updateCredits(-1);
        } else {
            alert('Not enough credits');
        }
    };

    return (
        <Box display="flex">
            <Sidebar />
            <CreditsDisplay credits={credits} loading={loading} company={companyName}/>
            <Box display="flex" flexDirection="column" flex={1} p={2}>
                <Box mb={2}>
                    <Typography variant="h4" gutterBottom sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}>
                        Leads
                    </Typography>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={handleMenuOpen}
                        >
                            Add New
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleAddNewLead}>Add Single Lead</MenuItem>
                            <MenuItem onClick={handleBulkUploadLeads}>Bulk Upload Leads</MenuItem>
                        </Menu>
                    </Box>
                </Box>
                <Divider sx={{ marginBottom: '20px' }} />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={tableHeaderCellStyle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Customer Info
                                    </div>
                                </TableCell>
                                <TableCell onClick={() => handleSort('name')} sx={tableHeaderCellStyle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Name {getSortIcon('name')}
                                    </div>
                                </TableCell>
                                <TableCell onClick={() => handleSort('email')} sx={tableHeaderCellStyle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Email {getSortIcon('email')}
                                    </div>
                                </TableCell>
                                <TableCell onClick={() => handleSort('phone')} sx={tableHeaderCellStyle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Phone {getSortIcon('phone')}
                                    </div>
                                </TableCell>
                                <TableCell onClick={() => handleSort('street')} sx={tableHeaderCellStyle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Street {getSortIcon('street')}
                                    </div>
                                </TableCell>
                                <TableCell onClick={() => handleSort('city')} sx={tableHeaderCellStyle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        City {getSortIcon('city')}
                                    </div>
                                </TableCell>
                                <TableCell onClick={() => handleSort('state')} sx={tableHeaderCellStyle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        State {getSortIcon('state')}
                                    </div>
                                </TableCell>
                                <TableCell onClick={() => handleSort('zip')} sx={tableHeaderCellStyle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Zip {getSortIcon('zip')}
                                    </div>
                                </TableCell>
                                <TableCell onClick={() => handleSort('company')} sx={tableHeaderCellStyle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Company {getSortIcon('company')}
                                    </div>
                                </TableCell>
                                <TableCell onClick={() => handleSort('userAssigned')} sx={tableHeaderCellStyle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        User Assigned {getSortIcon('userAssigned')}
                                    </div>
                                </TableCell>
                                <TableCell onClick={() => handleSort('leadSource')} sx={tableHeaderCellStyle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Lead Source {getSortIcon('leadSource')}
                                    </div>
                                </TableCell>
                                <TableCell onClick={() => handleSort('leadCycle')} sx={tableHeaderCellStyle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Lead Cycle {getSortIcon('leadCycle')}
                                    </div>
                                </TableCell>
                                <TableCell sx={tableHeaderCellStyle}>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedLeads.map((lead) => (
                                <TableRow 
                                key={lead.id} 
                                sx={(theme) => ({
                                  backgroundColor: lead.leadCycle === 'Qualified' 
                                    ? theme.palette.mode === 'dark' 
                                      ? theme.palette.qualifiedDark.main
                                      : theme.palette.qualifiedLight.main
                                    : 'inherit',
                                  color: lead.leadCycle === 'Qualified'
                                    ? theme.palette.mode === 'dark'
                                      ? theme.palette.qualifiedDark.contrastText
                                      : theme.palette.qualifiedLight.contrastText
                                    : 'inherit',
                                })}
                              >
                                    <TableCell>
                                        <IconButton onClick={() => handleOpenCustomerModal(lead)}>
                                            <InfoIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{lead.name}</TableCell>
                                    <TableCell>{lead.email}</TableCell>
                                    <TableCell>{lead.phone}</TableCell>
                                    <TableCell>{lead.street}</TableCell>
                                    <TableCell>{lead.city}</TableCell>
                                    <TableCell>{lead.state}</TableCell>
                                    <TableCell>{lead.zip}</TableCell>
                                    <TableCell>{lead.company}</TableCell>
                                    <TableCell>{lead.userAssigned}</TableCell>
                                    <TableCell>{lead.leadSource}</TableCell>
                                    <TableCell>{lead.leadCycle}</TableCell>
                                    <TableCell>
                                        {currentUser.role === 'Super Admin' && (
                                            <Button variant="contained" color="tertiary" onClick={() => handleEditLead(lead)}>
                                                <EditIcon />
                                            </Button>
                                        )}
                                        <Button variant="contained" color="secondary" onClick={() => archiveLead(lead)}>
                                            <ArchiveIcon />
                                        </Button>
                                        {lead.leadCycle === 'New Lead' && (
                                            <Button variant="contained" color="primary" onClick={() => handleEnrollLead(lead.id)}>
                                                Enroll
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <LeadForm
                    open={openLeadForm}
                    onClose={handleCloseLeadForm}
                    onAddLead={handleAddLead}
                    currentUser={currentUser}
                />
                <BulkUploadForm
                    open={openBulkUploadForm}
                    onClose={handleCloseBulkUploadForm}
                    onBulkUpload={handleBulkUpload}
                />
                <EditLeadModal
                    open={editModalOpen}
                    onClose={handleCloseEditModal}
                    lead={selectedLead}
                    companies={companies}
                    users={users}
                    onSave={handleSaveLead}
                />
                <CustomerDetailsModal
                    open={modalOpen}
                    onClose={handleCloseCustomerModal}
                    customer={selectedCustomer}
                />
            </Box>
        </Box>
    );
};

export default LeadListing;
