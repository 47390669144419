import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Select, MenuItem } from '@mui/material';
import { fetchIntegrations, addIntegration, updateIntegration, deleteIntegration } from '../services/IntegrationService';

const GeneralIntegrations = ({ currentUser }) => {
  const [integrations, setIntegrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newIntegration, setNewIntegration] = useState({
    name: '',
    type: '',
    apiKey: '',
    webhook: '',
  });

  useEffect(() => {
    fetchIntegrationsData();
  }, [currentUser]);

  const fetchIntegrationsData = async () => {
    if (currentUser) {
      const integrationsData = await fetchIntegrations(currentUser.companyId);
      setIntegrations(integrationsData);
    }
  };

  const handleAddIntegration = async () => {
    await addIntegration(currentUser.companyId, newIntegration);
    fetchIntegrationsData();
    setNewIntegration({ name: '', type: '', apiKey: '', webhook: '' });
  };

  const handleUpdateIntegration = async (integrationId, updatedData) => {
    await updateIntegration(currentUser.companyId, integrationId, updatedData);
    fetchIntegrationsData();
  };

  const handleDeleteIntegration = async (integrationId) => {
    await deleteIntegration(currentUser.companyId, integrationId);
    fetchIntegrationsData();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewIntegration((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    const getIntegrations = async () => {
      try {
        setLoading(true);
        const fetchedIntegrations = await fetchIntegrations(currentUser.companyId);
        setIntegrations(Object.values(fetchedIntegrations)); // Convert object to array
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (currentUser && currentUser.companyId) {
      getIntegrations();
    }
  }, [currentUser]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>General Integrations</Typography>
      
      {/* Form for adding new integrations */}
      <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: '20px', mb: 4 }}>
        <TextField
          name="name"
          label="Integration Name"
          value={newIntegration.name}
          onChange={handleInputChange}
        />
        <Select
          name="type"
          value={newIntegration.type}
          onChange={handleInputChange}
          displayEmpty
        >
          <MenuItem value="" disabled>Select Integration Type</MenuItem>
          <MenuItem value="CRM">CRM</MenuItem>
          <MenuItem value="Marketing">Marketing</MenuItem>
          <MenuItem value="Analytics">Analytics</MenuItem>
          {/* Add more integration types as needed */}
        </Select>
        <TextField
          name="apiKey"
          label="API Key"
          value={newIntegration.apiKey}
          onChange={handleInputChange}
        />
        <TextField
          name="webhook"
          label="Webhook URL"
          value={newIntegration.webhook}
          onChange={handleInputChange}
        />
        <Button variant="contained" color="primary" onClick={handleAddIntegration}>
          Add Integration
        </Button>
      </Box>

      {/* Table of existing integrations */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>API Key</TableCell>
              <TableCell>Webhook</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {integrations.length > 0 ? (
    integrations.map((integration) => (
      <TableRow key={integration.id}>
        <TableCell>{integration.name}</TableCell>
        <TableCell>{integration.type}</TableCell>
        <TableCell>{integration.apiKey}</TableCell>
        <TableCell>{integration.webhook}</TableCell>
        <TableCell>
          <Button onClick={() => handleUpdateIntegration(integration.id, { ...integration })}>
            Edit
          </Button>
          <Button onClick={() => handleDeleteIntegration(integration.id)}>
            Delete
          </Button>
        </TableCell>
      </TableRow>
    ))
  ) : (
    <p>No integrations found.</p>
  )}
</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default GeneralIntegrations;